<template>
  <el-container class="home_container">
    <!-- 头部区域 -->
    <el-header>
      <div>
        <img src="../assets/pic/logoHome.png" width="71" height="60" alt="" />
        <span>协作批注系统</span>
      </div>
      <div class="icon_button">
        欢迎你,{{ name }}
        <el-button
          type="primary"
          icon="el-icon-user"
          circle
          @click="person();"
        ></el-button>
        <el-button
          type="info"
          icon="el-icon-refresh-left"
          circle
          @click="back();"
        ></el-button>
      </div>
    </el-header>
    <!-- 页面主题区域 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '64px' : '200px'">
        <!-- <div class="toggle-button" @click="toggleCollapse">|||</div> -->
        <!-- <div class="toggle-button">|||</div> -->
        <!-- 侧边栏菜单区域 -->
        <el-menu
          background-color="#333744"
          text-color="#fff"
          active-text-color="#409EFF"
          overflow-y:
          scroll
          :default-active="$route.path"
          :collapse="isCollapse"
          :collapse-transition="false"
          :router="true"
        >
          <el-menu-item index="/allcourse">
            <i class="el-icon-zoom-in"></i>
            <span slot="title" >全部课程</span>
          </el-menu-item>
          <el-menu-item index="/selectcourse">
            <i class="el-icon-menu"></i>
            <span slot="title">已选课程</span>
          </el-menu-item>

          <el-menu-item index="/allreadingtasks">
            <i class="el-icon-tickets"></i>
            <span slot="title">所有任务</span>
          </el-menu-item>
          <div v-if="this.groupId == 0">
            <el-menu-item index="/readingtasknull">
              <i class="el-icon-tickets"></i>
              <span slot="title">阅读任务</span>
            </el-menu-item>
          </div>
          <div v-else>
            <el-menu-item index="/readingtask">
              <i class="el-icon-tickets"></i>
              <span slot="title">阅读任务</span>
            </el-menu-item>
          </div>
          <el-menu-item index="/studydata">
            <i class="el-icon-document"></i>
            <span slot="title">个人数据</span>
          </el-menu-item>
          <el-menu-item index="/groups">
            <i class="el-icon-location"></i>
            <span slot="title">小组成员</span>
          </el-menu-item>
          <el-menu-item index="/notice">
            <i class="el-icon-bell"></i>
            <span slot="title">通知公告</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <!-- 右侧内容主体 -->
      <el-main>
        <!-- 路由占位符 -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      name: "",
      isCollapse: false,
      groupId: "",
    };
  },
  mounted() {
    var username = localStorage.getItem("username");
    var groupId = localStorage.getItem("groupId");
    this.groupId = groupId;
    this.name = username;
    this.currentTime();
  },
  //点击按钮，左侧菜单整合
  methods: {
    currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
    },
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
     person() {
      this.$router.push("/person");
      this.saveLog("个人信息",this.nowDate);
      var userId = localStorage.getItem("userId");
      var username = localStorage.getItem("username");
      var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "个人信息",
          createTime: this.nowDate,
        })
        .then((res) => {});
    },
    back() {
      var userId = localStorage.getItem("userId");
      var username = localStorage.getItem("username");
      var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "退出系统",
          createTime: this.nowDate,
        })
        .then((res) => {});
        this.saveLog("退出系统",this.nowDate);
      this.$router.push("/login");
      sessionStorage.clear();
      localStorage.clear();
      localStorage.removeItem("userId");
      localStorage.removeItem("username");
      localStorage.removeItem("groupId");
      localStorage.removeItem("userAvatar");
      localStorage.removeItem("chatId");
      //this.downLog();
    },
   
  },
};
</script>

<style lang="less" scoped>
.el-header {
  background-color: #333744;
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
    }
  }
}
.el-aside {
  background-color: #333744;
  color: #333;
  .el-menu {
    border-right: none;
  }
}
.el-main {
  background-color: #e9eef3;
  color: #333;
}
.home_container {
  height: 100%;
}
.toggle-button {
  background-color: #4a5064;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
.icon_button {
  display: right;
}
</style>