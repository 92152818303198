<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/teachercourse' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>所有任务</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card>
      <div slot="header" class="clearfix" style="display: flex">
        <el-button
          style="flex: 1; text-align: right"
          type="text"
          @click="addTask()"
          >添加任务</el-button
        >
      </div>
      <el-table :data="tableData" style="width: 100%" :show-header="true" :row-style="setRowStyle">
        <!-- <el-table-column label="任务进度" width="80">
          <el-progress
            type="circle"
            :percentage="25"
            :width="50"
            :height="50"
          ></el-progress>
        </el-table-column> -->
        <el-table-column width="250" label="任务名">
          <template slot-scope="scope">
            <span>{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="explains" label="备注"> </el-table-column> -->
        <el-table-column label="结束时间" prop="endTime">
        </el-table-column>
        <el-table-column label="任务权限">
          <template slot-scope="scope">
            <span>{{ scope.row.currentAuthority }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="mini"
              @click="handleEdit(scope.$index, scope.row)"
              >查看
            </el-button>
            <el-button
              type="text"
              size="mini"
              @click="getStudyData(scope.$index, scope.row)"
              >学习数据
            </el-button>
            <el-button
              @click.native.prevent="deleteRow(scope.$index, tableData)"
              type="text"
              size="small"
            >
              删除
            </el-button>
            <el-button
              @click.native.prevent="updateTaskAuth(scope.$index, tableData)"
              type="text"
              size="small"
            >
              编辑权限
            </el-button>
            <el-button
              @click.native.prevent="updateTaskTime(scope.$index, tableData)"
              type="text"
              size="small"
            >
              修改结课时间
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog
      title="添加任务"
      :visible.sync="dialogFormVisible1"
      width="50%"
      close-on-click-modal
      close-on-press-escape
      show-close
    >
      <!-- <el-form :model="Form1" :rules="loginFormRules1" ref="FormRef1"> -->
      <el-form>
        <el-form-item label="任务名称" label-width="80px" prop="taskName">
          <el-input
            v-model="taskName"
            autocomplete="off"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="任务备注" label-width="80px" prop="explains">
          <el-input
            v-model="explains"
            autocomplete="off"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="结束时间" label-width="80px" prop="endTime">
          <el-date-picker
            v-model="endTime"
            type="datetime"
            placeholder="Select date and time"
            :shortcuts="shortcuts"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="任务权限" label-width="80px" prop="taskAuth">
          <el-select
            v-model="taskAuth"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              @click.native="selectAuth(item.value)"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="任务内容" label-width="80px">
          <div id = "richText" style="height:350px;max-height:400px;" ></div>          
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="修改任务截止时间"
      :visible.sync="dialogFormVisible2"
      width="30%"
      close-on-click-modal
      close-on-press-escape
      show-close
    >
      <el-form>
        <el-form-item prop="updatetime">
          <el-date-picker
            v-model="updatetime"
            type="datetime"
            placeholder="Select date and time"
            :shortcuts="shortcuts"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="savetime">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="修改任务权限"
      :visible.sync="dialogFormVisible3"
      width="30%"
      close-on-click-modal
      close-on-press-escape
      show-close
    >
      <el-form>
        <el-form-item label="任务权限" label-width="80px" prop="updateauth">
          <el-select
            v-model="updateauth"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              @click.native="updateAuth(item.value)"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="saveauth">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import E from 'wangeditor'
let editor;
import axios from "axios";
export default {
  data() {
    return {
      tableData: [],
      authority: 4, //全部：0  未截止已完成：1   未截止未完成：2   已截止已完成：3    已截止未完成：4
      array: [],
      nowDate: "", //当前日期
      taskName: "", //任务名
      endTime: "", //结束时间
      conTent: "", //内容
      explains: "", //备注
      taskAuth: "", //任务权限
      dialogFormVisible1: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      // Form1: {
      //   taskName: "", //任务名
      //   endTime: "", //结束时间
      //   conTent: "", //内容
      //   explains: "", //备注
      //   taskAuth: "", //任务权限
      // },
      shortcuts: [
        {
          text: "Today",
          value: new Date(),
        },
        {
          text: "Yesterday",
          value: () => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          },
        },
        {
          text: "A week ago",
          value: () => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          },
        },
      ],
      options: [
        {
          value: "0",
          label: "0仅学生自己可见",
        },
        {
          value: "1",
          label: "1仅小组可见",
        },
        {
          value: "2",
          label: "2公开可见",
        },
      ],
      options1: [
        {
          value: "0",
          label: "0仅学生自己可见",
        },
        {
          value: "1",
          label: "1仅小组可见",
        },
        {
          value: "2",
          label: "2公开可见",
        },
      ],
      // loginFormRules1: {
      //   taskName: [{ required: true, message: "请输入", trigger: "blur" }], //任务名
      //   endTime: [{ required: true, message: "请输入", trigger: "blur" }], //结束时间
      //   conTent: [{ required: true, message: "请输入", trigger: "blur" }], //内容
      //   explains: [{ required: true, message: "请输入", trigger: "blur" }], //备注
      //   taskAuth: [{ required: true, message: "请输入", trigger: "blur" }], //任务权限
      // },
      updatetime: "",
      timeid: "",
      updateauth: "",
      authid: "",
    };
  },
  created(){
    editor = '';
  },
  mounted() {
    //请求后台课程号courseId的所有的阅读任务
    var courseId = sessionStorage.getItem("courseId");
    if (courseId == 0) {
      this.$message.warning("请先选择一门课程");
    } else {
      axios
        .post(axios.defaults.baseURL + "taskList", {
          courseId: courseId,
        })
        .then((Response) => {
          this.tableData = Response.data.data;
          console.log(Response.data.data)
        });
    }
    this.currentTime();

  },
  methods: {
    selectAuth(val) {
      this.taskAuth = val;
    },
    //添加任务diag
    addTask() {
      this.dialogFormVisible1 = true;
      this.$nextTick(() => {
        if(!editor) {
          editor = new E("#richText");
          editor.config.uploadImgServer = axios.defaults.baseURL +'/editor/uploadImg';
          editor.config.uploadFileName = 'file';
          editor.create();
        }
        editor.txt.html('');  // 清除内容
      })
    },
    //保存任务
    save() {
      var courseId = sessionStorage.getItem("courseId");
      const conTent = editor.txt.html();
      console.log(conTent);
      console.log(
        "课程号" + courseId,
        "任务名" + this.taskName,
        "内容" + conTent,
        "备注" + this.explains,
        "结束时间" + this.endTime,
        "任务权限" + this.taskAuth
      );
      axios
        .post(axios.defaults.baseURL + "addTask", {
          courseId: courseId,
          title: this.taskName,
          explains: this.explains,
          endTime: this.endTime,
          currentAuthority: this.taskAuth,
          content: conTent,
        })
        .then((response) => {
          console.log(response.data);
          this.$message.success("添加成功");
          this.dialogFormVisible1 = false;
          axios
            .post(axios.defaults.baseURL + "taskList", {
              courseId: courseId,
            })
            .then((Response) => {
              this.tableData = Response.data.data;
            });
        });
    },
    //删除任务
    deleteRow(index, rows) {
      axios
        .post(axios.defaults.baseURL + "deleteTask", {
          id: rows[index].id,
        })
        .then((Response) => {
          if (Response.data.status == 1) {
            this.$message.success("删除成功");
            rows.splice(index, 1);
            this.tableData = rows;
          }
        });
    },
    updateTaskTime(index, rows) {
      this.dialogFormVisible2 = true;
      this.timeid = rows[index].id;
    },
    //编辑任务diag
    savetime() {
      axios
        .post(axios.defaults.baseURL + "updateEndTime", {
          id: this.timeid,
          endTime: this.updatetime,
        })
        .then((response) => {
          this.$message.success("修改成功");
          this.dialogFormVisible2 = false;
          var courseId = sessionStorage.getItem("courseId");
          axios
            .post(axios.defaults.baseURL + "taskList", {
              courseId: courseId,
            })
            .then((Response) => {
              this.tableData = Response.data.data;
            });
        });
    },
    updateAuth(val){
      this.updateauth=val;
    },
    updateTaskAuth(index, rows) {
      this.dialogFormVisible3 = true;
      this.authid = rows[index].id;
    },
    saveauth() {
      axios
        .post(axios.defaults.baseURL + "updateCurrentAuthority", {
          id: this.authid,
          currentAuthority: this.updateauth,
        })
        .then((response) => {
          this.$message.success("修改成功");
          this.dialogFormVisible3 = false;
          var courseId = sessionStorage.getItem("courseId");
          axios
            .post(axios.defaults.baseURL + "taskList", {
              courseId: courseId,
            })
            .then((Response) => {
              this.tableData = Response.data.data;
            });
        });
    },
    //任务进度
    showreadingtask(authority) {
      //this.authority=authority;
      switch (authority) {
        case 0: //全部 0
        case 1: //未截止已完成：1
        case 2: //未截止未完成：2
        case 3: //已截止已完成：3
        case 4: //已截止未完成：4
      }
    },
    setItemText(row) {
      return () => {
        return this.timediff(this.nowDate, row);
      };
    },
    // Read(index,row,val){ 
    //   sessionStorage.setItem("readingtaskId", row.id);
    //   var groupId = localStorage.getItem("groupId");
    //   if (groupId == 0) {
    //     this.$router.push("/readingtasknull"); 
    // }
    // },
    getStudyData(index,row){
      // sessionStorage.getItem("readingtaskId");
      sessionStorage.setItem("readingtaskId",row.id);
      this.$router.push("/allstudentData");
    },
    handleEdit(index, row) {
      sessionStorage.setItem("readingtaskId", row.id);
      this.$router.push("/scorereadingtask");
    },
    //获取当前年月日
    currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      // this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
      this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second}`;
    },
    timediff(beginTime, endTime) {
      var dateBegin = new Date(beginTime);
      var dateEnd = new Date(endTime);
      var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      this.seconds = seconds;
      this.minutes = minutes;
      return (
        "任务截止还剩" +
        dayDiff +
        "天" +
        hours +
        "小时" +
        minutes +
        "分钟" +
        seconds +
        "秒"
      );
    },
    timecha(beginTime, endTime) {
      var dateBegin = new Date(beginTime);
      var dateEnd = new Date(endTime);
      var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      this.seconds = seconds;
      this.minutes = minutes;
      return leave1
    },
    setRowStyle(row) {
      let stylejson={}
      // console.log("时间差"+this.timecha(row.row.startTime,row.row.endTime))
      if ((this.timecha(this.nowDate,row.row.endTime))<0) {
        // return 'color:red;'
        stylejson.color='red'
        return stylejson
      }
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-progress-bar__outer {
    background-color: rgba(255, 251, 251, 0.459);
}
</style>
