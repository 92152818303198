import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
// 导入全局样式表
import '@/assets/CSS/global.css'
//导入字体图标
import './assets/fonts/iconfont.css'
import './assets/font_lock/iconfont.css'


import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import VueContextMenu from 'vue-contextmenu'
Vue.use(VueContextMenu)

//聊天组件
import LemonIMUI from "lemon-imui";
import "lemon-imui/dist/index.css";
Vue.use(LemonIMUI);

//配置请求的根路径
// axios.defaults.baseURL = "http://112.124.66.114:8080/readingannotation/";
axios.defaults.baseURL = "http://47.99.140.190:8080/readingannotation/";
axios.defaults.chatURL = "http://47.99.140.190:8081/";
axios.defaults.drawURL = " ";
//需要改成服务器上的url

// axios.defaults.baseURL = "http://10.148.74.80:8080/readingannotation/"

import Debugout from '../debugout.js'
const bugout = new Debugout();
Vue.prototype.saveLog=function(a,b){
  bugout.log("Step:"+" "+a+"     "+"Time:"+" "+b);
  bugout.log("****************************************************************");
}
Vue.prototype.downLog=function(){
  bugout.downloadLog();
}

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
