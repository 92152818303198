<template>
    <div class="stu-chat">
        <div  @click="togglePanel">
          <el-button>打开/重置聊天面板</el-button>
        </div>
        <!-- <button @click="handleSendBtnClick">123</button> -->
        <div class="chat-container animated fadeIn" v-show="show" ref="panel" @mousedown="mousedown" @touchstart.stop="touchStart">
          <div class="close" @click="show = false"><i class="el-icon-close"></i></div>
          <lemon-imui
              :user="user"
              ref="IMUI"
              :contextmenu="contextmenu"
              :contact-contextmenu="contactContextmenu"
              :theme="theme"
              :hide-menu="hideMenu"
              :hide-menu-avatar="hideMenuAvatar"
              :hide-message-name="hideMessageName"
              :hide-message-time="hideMessageTime"
              @change-menu="handleChangeMenu"
              @change-contact="handleChangeContact"
              @pull-messages="handlePullMessages"
              @message-click="handleMessageClick"
              @send="handleSend"
              width="900px"
          />

        </div>
        <!-- 图片预览 -->
        <div @click="showImg=false" style="position:fixed;width:100vw;top:0;left:0;height:100vh;background:rgba(0,0,0,.5);" v-if="showImg">
          <el-image :src="imgUrl" style="width:350px;position:absolute;left:50%;top:50%;transform:translatex(-50%) translateY(-50%)" fit="cover"></el-image>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import QQIMUI from "../plugins/qq";
import EmojiData from "../plugins/database/emoji";
import lemonMessageNotice from "../plugins/lemon-message-notice";
Vue.component(lemonMessageNotice.name, lemonMessageNotice);
Vue.component(QQIMUI.name, QQIMUI);

const getTime = () => {
  return new Date().getTime();
};
const generateRandId = () => {
  return Math.random()
    .toString(36)
    .substr(-8);
};
const generateRandWord = () => {
  return Math.random()
    .toString(36)
    .substr(2);
};
const chatUrl = 'http://47.99.140.190:8001';
export default {
  data() {
    return {
      ws:null,
      msg:"打开聊天面板",
      show:false,
      showImg:false,
      imgUrl:'',
      theme: "default",
      // 小组成员
      member:[],
      //老师成员
      teacher:[],
      // 右键点击联系人功能面板
      contactContextmenu: [
        {
          text: "删除该聊天",
          click: (e, instance, hide) => {
            const { IMUI, contact } = instance;
            IMUI.updateContact({
              id: contact.id,
              lastContent: null,
            });
            if (IMUI.currentContactId == contact.id) IMUI.changeContact(null);
            hide();
          },
        },
        {
          text: "设置备注和标签",
        },
        {
          text: "投诉",
        },
        {
          icon: "lemon-icon-message",
          render: (h, instance, hide) => {
            return (
              <div style="display:flex;justify-content:space-between;align-items:center;width:130px">
                <span>加入黑名单</span>
                <span>
                  <input type="checkbox" id="switch" />
                  <label id="switch-label" for="switch">
                    Toggle
                  </label>
                </span>
              </div>
            );
          },
        },
        {
          click(e, instance, hide) {
            const { IMUI, contact } = instance;
            IMUI.removeContact(contact.id);
            if (IMUI.currentContactId == contact.id) IMUI.changeContact(null);
            hide();
          },
          color: "red",
          text: "删除好友",
        },
      ],
      // 右键点击消息面板
      contextmenu: [
        {
          click: (e, instance, hide) => {
            const { IMUI, message } = instance;
            const data = {
              id: generateRandId(),
              type: "event",
              //使用 jsx 时 click必须使用箭头函数（使上下文停留在vue内）
              content: (
                <div>
                  <span>
                    你撤回了一条消息{" "}
                    <span
                      v-show={message.type == "text"}
                      style="color:#333;cursor:pointer"
                      content={message.content}
                      on-click={e => {
                        IMUI.setEditorValue(e.target.getAttribute("content"));
                      }}
                    >
                      重新编辑
                    </span>
                  </span>
                </div>
              ),

              toContactId: message.toContactId,
              sendTime: getTime(),
            };
            IMUI.removeMessage(message.id);
            IMUI.appendMessage(data, true);
            hide();
          },
          visible: instance => {
            return instance.message.fromUser.id == this.user.id;
          },
          text: "撤回消息",
        },
        {
          visible: instance => {
            return instance.message.fromUser.id != this.user.id;
          },
          text: "举报",
        },
        {
          text: "转发",
        },
        {
          visible: instance => {
            return instance.message.type == "text";
          },
          text: "复制文字",
        },
        {
          visible: instance => {
            return instance.message.type == "image";
          },
          text: "下载图片",
        },
        {
          visible: instance => {
            return instance.message.type == "file";
          },
          text: "下载文件",
        },
        {
          click: (e, instance, hide) => {
            const { IMUI, message } = instance;
            IMUI.removeMessage(message.id);
            hide();
          },
          icon: "lemon-icon-folder",
          color: "red",
          text: "删除",
        },
      ],
      hideMenuAvatar: false,
      hideMenu: false,
      hideMessageName: false,
      hideMessageTime: true,
      //个人信息
      user: {
        id: localStorage.getItem('chatId'),
        displayName: localStorage.getItem('username'),
        avatar: localStorage.getItem('userAvatar'),
      },
    };
  },
  watch:{
    //等待父组件的异步小组成员数据传下来之后再初始化联系人
    member(){
          // ...调用接口初始化联系人以及群组数据
          const { IMUI } = this.$refs;
          let contactList = this.member.reduce((prev,item,index)=>{
          if(item.id == localStorage.getItem('userId')) return prev;
          prev.push({
            id:item.stuId,
            displayName:item.username,
            avatar: "http://upload.qqbodys.com/img/weixin/20170804/ji5qxg1am5ztm.jpg",
            index:`[2]联系人`,
            unread:0,
            lastSendTime: 0,
            lastContent: " ",
          })
          return prev;
        },[]);

        contactList.unshift({
          id:localStorage.getItem('groupId'),
          displayName:`第${localStorage.getItem('groupId')}组`,
          avatar: "http://upload.qqbodys.com/img/weixin/20170804/ji5qxg1am5ztm.jpg",
          index:`[1]群组`,
          unread:0,
          lastSendTime: 0,
          lastContent: " ",
        })
        
        for(let item of this.teacher){
          contactList.push({
            id:item.user_id,
            displayName:item.username,
            avatar:item.avatar,
            index:`[3]教师`,
            unread:0,
            lastSendTime: 0,
            lastContent: " ",
          })
        }
        //初始化聊天联系人板块
        IMUI.initContacts(contactList);

        setTimeout(() => {
          IMUI.changeContact(contactList[0].id);
        }, 500);
    }
  },
  mounted() {
    console.log('courseId',sessionStorage.getItem('courseId'))
    // 初始化收到的通知
    if(!localStorage.getItem('receivedNotice')){
      localStorage.setItem('receivedNotice',JSON.stringify([-1]))
    }
    //解决顶部loading切换路由后会一直转圈圈的bug，这里应该调用后端接口之前显示loading，成功调用后显示loadend
    // setTimeout(() => {
    //   document.querySelector('.lemon-messages__loading').style.display = 'none';
    //   document.querySelector('.lemon-messages__loadend').style.display = 'block';
    // }, 1000);
    
    document.querySelector('.lemon-messages__loadend').style.display = 'block';

    //获取小组成员数据，原用户数据库切割了几张表，字段也没对应好，只能拼接
    axios.post(axios.defaults.baseURL + "listAllMember", {
        groupId: localStorage.getItem("groupId"),
      })
      .then((res) => {
        axios.get(chatUrl+"/teacherList?courseId="+sessionStorage.getItem('courseId')).then((data)=>{
            this.teacher = data.data.teacherList;
            this.member = res.data.data;
        })
      });

    const { IMUI } = this.$refs;
    // 输入框上方工具栏
    IMUI.initEditorTools([
      {
        name: "emoji",
      },
      {
        name: "uploadImage",
      },
      {
        name: "",
        isRight: true,
        title: "",
        render: () => {
          return <b>···</b>;
        },
      },
    ]);
    
    //初始化表情
    IMUI.initEmoji(EmojiData);

    //初始化限时任务左侧消息预览文字
    IMUI.setLastContentRender("notice", message => {
        return `[限时任务]`;
    });

    IMUI.messageViewToBottom();
    this.ws = new WebSocket(`ws://47.99.140.190:8000?userId=${localStorage.getItem('chatId')}`);
    this.ws.onopen = (e) => {
      console.log('打开连接')
    };

    this.ws.onclose = (e) => {
      console.log('断开连接',e)
    }

    //监听消息
    this.ws.onmessage = (e) => {
      let info = JSON.parse(e.data);
      console.log('接收消息',info);
      if(info.fromUserId == localStorage.getItem('chatId')) return;
      if(info.msgType == 'notice'){
        let content = JSON.parse(info.content);
        console.log(content)
        const data = {
          id: info.id,
          status: 'succeed',
          type: 'notice',
          sendTime: info.sendTime,
          content: JSON.parse(info.content),
          toContactId: info.toContactId,
          fromUser:{
            //如果 id == this.user.id消息会显示在右侧，否则在左侧
            id:info.fromUserId,
            displayName:info.fromUsername,
            avatar:info.fromUserAvatar,
          }
        };
        console.log(data)
        IMUI.appendMessage(data);
        IMUI.messageViewToBottom();
        return;
      }

      const data = {
        id: generateRandId(),
        status: 'succeed',
        type: info.msgType,
        sendTime: new Date().getTime(),
        content: info.content,
        toContactId: info.type=='group'?info.toContactId:info.fromUserId,
        fromUser:{
          //如果 id == this.user.id消息会显示在右侧，否则在左侧
          id:info.fromUserId,
          displayName:info.fromUsername,
          avatar:info.fromUserAvatar,
        }
      };
      IMUI.appendMessage(data);
      IMUI.messageViewToBottom();
    }
      

  },
  methods: {
    //点击确定收到
    handleMessageClick(e, key, message) {
        console.log(message)
        //预览图片
        if(message.type=='image'){
          this.showImg = true;
          this.imgUrl = message.content;
        }
        if(e.target.innerText == '确认收到'){
            if(new Date(message.content.date1 + ' ' + message.content.date2) < Date.now()){
              this.$message.error('该任务已结束！');
              return;
            }
            //执行确认收到的后端接口
            let arr = JSON.parse(localStorage.getItem('receivedNotice'));
            arr.push(message.id);
            localStorage.setItem('receivedNotice',JSON.stringify(arr));
            e.target.innerText = "已收到";
            localStorage.setItem('taskId',message.content.taskId);
            localStorage.setItem('finishTime',message.content.date1+' '+message.content.date2);
            this.$message.success('成功参与讨论！');
        }

        let { IMUI } = this.$refs;
        if (key == "status" && message.status === "failed") {
            IMUI.updateMessage({
            id:message.id,
            toContactId:message.toContactId,
            status: "going",
            content: "重新发送消息...",
            });
            setTimeout(() => {
            IMUI.updateMessage({
                id:message.id,
                toContactId:message.toContactId,
                status: "failed",
                content: "还是发送失败",
            });
            }, 2000);
        }
    },
    removeMessage() {
      const { IMUI } = this.$refs;
      const messages = IMUI.getCurrentMessages();
      const id = messages[messages.length - 1].id;
      if (messages.length > 0) {
        IMUI.removeMessage(id);
      }
    },
    updateMessage() {
      const { IMUI } = this.$refs;
      const messages = IMUI.getCurrentMessages();
      const message = messages[messages.length - 1];
      if (messages.length > 0) {
        const update = {
          id: message.id,
          status: "succeed",
          type: "file",
          fileName: "被修改成文件了.txt",
          fileSize: "4200000",
        };
        if (message.type == "event") {
          update.fromUser = this.user;
        }
        IMUI.updateMessage(update);
        IMUI.messageViewToBottom();
      }
    },
    //发送限时任务 群通知
    appendNoticeMessage( ) {
        const { IMUI } = this.$refs;
        const message = {
            id: generateRandId(),
            status: "succeed",
            type: "notice",
            sendTime: getTime(),
            content: {
                title:this.form.title,
                introduction:this.form.introduction,
                date1:this.form.date1,
                date2:this.form.date2
            },
            toContactId: "group",
            fromUser: this.user,
        };
        IMUI.appendMessage(message, true);
    },
    appendMessage() {
      // const { IMUI } = this.$refs;
      // const contact = IMUI.currentContact;
      // const message = generateMessage("contact-3");
      // message.fromUser = {
      //   ...message.fromUser,
      //   ...this.user,
      // };
      // IMUI.appendMessage(message, true);
    },
    updateContact() {
      this.$refs.IMUI.updateContact({
        id: "contact-3",
        unread: 10,
        displayName: generateRandWord(),
        lastSendTime: getTime(),
        lastContent: "修改昵称为随机字母",
      });
    },
    handleChangeContact(contact, instance) {
      instance.updateContact({
        id: contact.id,
        unread: 0,
      });
    },
    //本人发送消息
    handleSend(message, next, file) {
      if(message.type == 'image'){
        let fd = new FormData();
        fd.append('file',file,file.fileName)
        axios.post(chatUrl + '/upload',fd,{
          headers: { "content-type": `multipart/form-data;boundary='+${new Date().getTime()}` }
        }).then(res=>{
          this.ws.send(JSON.stringify({
            fromUserId:localStorage.getItem('chatId'),
            fromUsername:localStorage.getItem('username'),
            fromUserAvatar:localStorage.getItem('userAvatar'),
            toContactId:message.toContactId,
            content:res.data.imgUrl,
            // 学生userId要求10位字符 老师id要求3位字符
            type:(message.toContactId.length==10||message.toContactId.length==3)?'user':'group',
            msgType:'image',
            taskId:localStorage.getItem('taskId'),
            finishTime:localStorage.getItem('finishTime')
          }));
          next();
        });
      }else{
        this.ws.send(JSON.stringify({
          fromUserId:localStorage.getItem('chatId'),
          fromUsername:localStorage.getItem('username'),
          fromUserAvatar:localStorage.getItem('userAvatar'),
          toContactId:message.toContactId,
          content:message.content,
          // 学生userId要求10位字符 老师id要求3位字符
          type:(message.toContactId.length==10||message.toContactId.length==3)?'user':'group',
          msgType:'text',
          taskId:localStorage.getItem('taskId'),
          finishTime:localStorage.getItem('finishTime')
        }));
        next();
      }
    },
    //从后端请求消息数据（私聊）
    handlePullMessages(contact, next, instance) {
      document.querySelector('.lemon-messages__loadend').style.display = 'none';
      axios.get(`${chatUrl}/historyMsg?userId=${localStorage.getItem('chatId')}&groupId=${localStorage.getItem('groupId')}`)
      .then(res=>{
        let message_history = res.data.msgList.reduce((prev,item)=>{
          //逻辑处理 显示私聊和群聊 的信息 并进行分割
          if((item.toContactId==contact.id&&localStorage.getItem('chatId')==item.fromUserId)||
             (item.fromUserId==contact.id&&localStorage.getItem('chatId')==item.toContactId)||
             (item.toContactId==contact.id&&localStorage.getItem('groupId')==item.toContactId)){
            prev.push({
              id:item.id,
              status: 'succeed',
              type: item.type,
              sendTime:item.sendTime,
              content:(item.type=='text'||item.type=='image')?item.content:JSON.parse(item.content),
              toContactId:item.toContactId,
              fromUser:{
                id:item.fromUserId,
                displayName:item.fromUsername,
                avatar:item.fromUserAvatar
              }
            });
          }
          
          return prev; 
      },[]);
      document.querySelector('.lemon-messages__loadend').style.display = 'block';
      next(message_history,true);  

      const { IMUI } = this.$refs;
      IMUI.messageViewToBottom();
      })
    },
    handleChangeMenu() {
      console.log("Event:change-menu");
    },
    openCustomContainer() {},
    togglePanel(){
      this.show = true;
      this.selectElement = this.$refs.panel;
      var panel = this.selectElement;
      panel.style.top = '50%';
      panel.style.left = '50%';
      const { IMUI } = this.$refs;
      IMUI.messageViewToBottom();
    },
    //脱拽
    mousedown(event) {
        this.selectElement = this.$refs.panel;
        var div1 = this.selectElement
        this.selectElement.style.cursor = 'move'
        this.isDowm = true
        var distanceX = event.clientX - this.selectElement.offsetLeft
        var distanceY = event.clientY - this.selectElement.offsetTop
        document.onmousemove = function (ev) {
          var oevent = ev || event
          div1.style.left = oevent.clientX - distanceX + 'px'
          div1.style.top = oevent.clientY - distanceY + 'px'
        }
        document.onmouseup = function () {
          document.onmousemove = null
          document.onmouseup = null
          div1.style.cursor = 'default'
        }
    },

    touchStart(event) {
        this.selectElement = this.$refs.panel;
        var div1 = this.selectElement
        this.selectElement.style.cursor = 'move'
        this.isDowm = true
        var touchDistanceX = event.changedTouches[0].clientX - this.selectElement.offsetLeft
        var touchDistanceY = event.changedTouches[0].clientY - this.selectElement.offsetTop
        this.$refs.panel.addEventListener('touchmove', function (ev) {
          var oevent = ev || event
          div1.style.left = oevent.changedTouches[0].clientX - touchDistanceX + 'px'
          div1.style.top = oevent.changedTouches[0].clientY - touchDistanceY + 'px'
          //拖动时穿透
          ev.preventDefault()
        }, { passive: false })
        this.$refs.panel.addEventListener('touchend', function () {
          div1.style.cursor = 'default'
        })
        //拖动时穿透
        ev.preventDefault()
    },

    getCookie(name){
      var arr,reg=new RegExp("(^| )"+name+"=([^;]*)(;|$)");
      if(arr=document.cookie.match(reg))
      return unescape(arr[2]);
      else
      return null;
    }
  },
};
</script>

<style lang="less" scoped>
.chat-container{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 900px;
  box-shadow: 0 0 6px 0px #424242;
}
.close{
  position: absolute;
  top: 8px;
  right: 8px;
  color: rgb(141, 141, 141);
  z-index: 999;
  cursor: pointer;
  font-size: 20px;
}
</style>