<template>
  <div>
    <!-- 高亮按钮 -->
    <el-button id="js-highlight" class="btnHighlight" @click="highlightText"
      >高亮</el-button
    >

    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/allcourse' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>阅读任务</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 阅读区域 -->
    <div style="display: flex; width: 100%; height: 100%">
      <el-card style="width: 1200px; margin-right: 20px; height: 100%">
        <div slot="header">
          <div style="margin: 20px 0">
            <!--小组是1，个人是0，公共是2 -->
            <!-- <el-button
              v-for="(list, index) in leftPart"
              @click="
                showArticleHL(index);
                leftChange(index);
              "
              :class="{ liBackground: changeLeftBackground == index }"
            >
              {{ list.name }}
            </el-button> -->
            <el-button
              :class="{ liBackground: changeLeftBackground == 2 }"
              @click="
                showArticleHL(2);
                leftChange(2);
              "
              >公共高亮</el-button
            >
          </div>
        </div>
        <div style="height: 600px; width: 100%">
          <el-scrollbar id="scro" style="height: 100%">
            <div
              class="selfContainer"
              id="articleDiv"
              v-html="articleHtml"
            ></div>
          </el-scrollbar>
        </div>
      </el-card>

      <!-- 评论区域 -->
      <el-card style="width: 600px; height: 100%">
        <div style="height: 717px; width: 100%">
          <el-scrollbar style="height: 100%">
            <div id="comment" class="col-md-9 rightBox">
              <div style="margin: 20px 0">
                <h3>高亮文字</h3>
                <p style="font-style: italic; color: #ff915b">
                  {{ currentHLText }}
                </p>
                <!-- 高亮名字、高亮时间 -->
                <p>
                  {{ currentHLTextName }}
                </p>
                <p>
                  {{ currentHLTextTime }}
                </p>
                <el-button
                  id="delHLbtn"
                  type="primary"
                  @click="delHL"
                  :disabled="delHLbtnDis"
                  icon="el-icon-delete"
                  size="mini"
                  >删除</el-button
                >
              </div>

              <CommentContent
                v-bind:articleId="this.articleId"
                v-bind:hlId="this.currentHLId"
                v-bind:comment="this.hlComments"
                v-bind:commentText="this.currentHLText"
                v-bind:replyment="this.replyment"
                @child="father"
                @childhuifu="fatherhuifu"
              ></CommentContent></div>
          <TeaChat />
          </el-scrollbar>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import Highlighter from "web-highlighter";
import LocalStore from "../local.store.js";
import axios from "axios";
import CommentContent from "./CommentContent";
import TeaChat from "./TeaChat";
let highlighter = null;
const store = new LocalStore();
const log = console.log.bind(console, "[highlighter]");
sessionStorage.setItem("init", 0);

export default {
  data() {
    return {
      //leftPart: [{ name: "仅自己可见" }, { name: "公开可见" }],
      changeLeftBackground: 0,

      articleId: "",
      articleHtml: "", //所需要高亮评论的文本
      currentHLText: "",
      currentHLTextName: "",
      currentHLTextTime: "",
      currentHLId: "",
      authority: 0, //个人是0，公共是2
      delHLbtnDis: true,
      hlComments: [], //所选择高亮的所有评论
      replyment: [], //评论的回复
    };
  },

  mounted() {
    highlighter = new Highlighter({
      $root: document.getElementById("articleDiv"),
      wrapTag: "i",
      exceptSelectors: [".my-remove-tip", "pre", "code"],
    });
    //根据阅读课程ID和阅读任务ID，显示响应的富文本
    var value = sessionStorage.getItem("readingtaskId");
    this.articleId = value;
    if (this.articleId == 0) {
      this.$message.warning("请先选择一项阅读任务");
    } else {
      axios
        .post(axios.defaults.baseURL + "selectContent", {
          id: value,
        })
        .then((response) => {
          let textareaHtml = response.data.data.content;
          if (textareaHtml) {
            this.articleHtml = textareaHtml;
          }
        });
    }
    //初始化显示仅自己显示的高亮;
    //var value = sessionStorage.getItem("readingtaskId");
    var userId = localStorage.getItem("userId");
    axios
      .get(axios.defaults.baseURL + "articleTask/showHighlightInfoListByUser", {
        params: {
          articleId: value,
          userId: userId,
        },
      })
      .then((response) => {
        sessionStorage.setItem("init", 1);
        log(this.init);
        const storeInfos = response.data.data;
        highlighter.removeAll();
        //画出所有高亮内容
        storeInfos.forEach(({ hs }) =>
          highlighter.fromStore(
            hs.startMeta,
            hs.endMeta,
            hs.text,
            hs.id,
            hs.extra
          )
        );
        sessionStorage.setItem("init", 0);
      })
      .catch(function (error) {
        alert(error);
        return [];
      });

    highlighter.hooks.Serialize.Restore.tap((source) =>
      log("Serialize.Restore hook -", source)
    );

    highlighter.hooks.Serialize.RecordInfo.tap(() => {
      const extraInfo = Math.random().toFixed(4);
      log("Serialize.RecordInfo hook -", extraInfo);
      return extraInfo;
    });

    /**
     * highlighter event listener
     */
    highlighter
      .on(Highlighter.event.CLICK, ({ id }) => {
        var hlDoms = highlighter.getDoms(id);
        const position = this.getPosition(hlDoms);
        this.delHLbtnDis = false;
        this.currentHLId = id;
        sessionStorage.setItem("highlightId", this.currentHLId);
        this.currentHLText = "";
        //设置高亮文字
        for (var i in hlDoms) {
          this.currentHLText += hlDoms[i].innerText;
        }
        //设置高亮相应的评论
        axios
          .get(axios.defaults.baseURL + "articleTask/showHighlight", {
            params: {
              highlightId: this.currentHLId,
            },
          })
          .then((res) => {
            axios
              .get(axios.defaults.baseURL + "findUserById", {
                params: {
                  id: res.data.data.userId,
                },
              })
              .then((res) => {
                this.currentHLTextName = res.data.data.username;
              });
            this.currentHLTextTime = res.data.data.createTime;
          });
        this.hlComments = this.getCommentsByHLid(id);
        //log("click -", id);
      })
      .on(Highlighter.event.CREATE, ({ sources }) => {
        if (sessionStorage.getItem("init") != 1) {
          //log("create -", this.authority + sources);
          sources = sources.map((hs) => ({ hs }));
          var groupId = localStorage.getItem("groupId");
          store.save(this.authority, sources);
          // if (groupId == 0 && this.changeLeftBackground == 1) {
          //   store.save(this.authority + 1, sources);
          // } else {
          //   store.save(this.authority, sources);
          // }
        }
      })
      .on(Highlighter.event.REMOVE, ({ ids }) => {
        if (sessionStorage.getItem("init") != 1) {
          //log("remove -", ids);
        }
      })
      .on(Highlighter.event.HOVER, ({ id }) => {
        highlighter.addClass("highlight-wrap-hover", id);
        //log("hover - ", id);
      })
      .on(highlighter.event.HOVER_OUT, ({ id }) => {
        highlighter.removeClass("highlight-wrap-hover", id);
        //log("hover_out - ", id);
      });

    let hoveredTipId;
    document.addEventListener("mouseover", (e) => {
      const $ele = e.target;
      // toggle highlight hover state
      if (
        $ele.classList.contains("my-remove-tip") &&
        hoveredTipId !== $ele.dataset.id
      ) {
        hoveredTipId = $ele.dataset.id;
        highlighter.removeClass("highlight-wrap-hover");
        highlighter.addClass("highlight-wrap-hover", hoveredTipId);
      } else if (
        !$ele.classList.contains("my-remove-tip") &&
        !$ele.classList.contains("highlight-mengshou-wrap")
      ) {
        highlighter.removeClass("highlight-wrap-hover", hoveredTipId);
        hoveredTipId = null;
      }
    });

    //自定义一个浏览器右键菜单，单击右键是显示它
    //oncontextmenu上下文菜单事件，右键菜单
    document.documentElement.onmouseup = function (e) {
      e = e || window.event;
      var mx = e.clientX;
      var my = e.clientY;
      var articleDiv = document.getElementById("articleDiv");
      var scro = document.getElementById("scro");
      if (
        mx > scro.offsetLeft &&
        mx < scro.offsetLeft + scro.offsetWidth &&
        my > scro.offsetTop &&
        my < scro.offsetTop + scro.offsetHeight
      ) {
        var rm = document.getElementById("js-highlight");
        var rmWidth = parseInt(rm.style.width);
        var pageWidth = document.documentElement.clientWidth;
        if (mx + rmWidth < pageWidth) {
          rm.style.left = e.pageX + "px";
          rm.style.top = e.pageY + "px";
          rm.style.position = "absolute";
        } else {
          rm.style.left = e.pageX + "px";
          rm.style.top = e.pageY + "px";
        }
        rm.style.display = "block";

        return false; //阻止默认的右键菜单显示
      } else document.getElementById("js-highlight").style.display = "none";
    };

    //不需要积隐藏右键菜单
    document.documentElement.onclick = function (e) {
      document.getElementById("js-highlight").style.display = "none";
    };
  },

  methods: {
    leftChange(index) {
      this.changeLeftBackground = index;
    },
    //子组件向父组件传数据
    father(data) {
      this.hlComments = data;
    },
    //子组件向父组件传数据
    fatherhuifu(data) {
      this.replyment = data;
    },
    //显示当前文章的高亮信息
    showArticleHL(authority) {
      this.hlComments = null;
      this.replyment = null;
      this.currentHLText = null;
      this.currentHLTextName = null;
      this.currentHLTextTime = null;
      this.authority = authority;
      var requestURL = "";
      var requestParams = {};
      var articleId = sessionStorage.getItem("readingtaskId");
      var groupId = localStorage.getItem("groupId");
      var userId = localStorage.getItem("userId");
      //仅公共高亮
      sessionStorage.setItem("authority", authority);

      //个人和公共高亮
      // if (groupId == 0 && this.authority == 1) {
      //   sessionStorage.setItem("authority", authority + 1);
      // } else {
      //   sessionStorage.setItem("authority", authority);
      // }
      // switch (authority) {
      //   case 0: //个人
      //     requestURL =
      //       axios.defaults.baseURL + "articleTask/showHighlightInfoListByUser";
      //     requestParams = { articleId: articleId, userId: userId };
      //     break;
      //   case 1: //公共
      //     requestURL =
      //       axios.defaults.baseURL + "articleTask/showPublicHighlightInfoList";
      //     requestParams = { articleId: articleId };
      // }

      axios
        // .get(requestURL, {
        //   params: requestParams,
        // })
        .get(
          axios.defaults.baseURL + "articleTask/showPublicHighlightInfoList",
          {
            params: {
              articleId: articleId,
            },
          }
        )
        .then((response) => {
          sessionStorage.setItem("init", 1);
          log(this.init);
          const storeInfos = response.data.data;
          highlighter.removeAll();
          //画出所有高亮内容
          storeInfos.forEach(({ hs }) =>
            highlighter.fromStore(
              hs.startMeta,
              hs.endMeta,
              hs.text,
              hs.id,
              hs.extra
            )
          );
          sessionStorage.setItem("init", 0);
        })
        .catch(function (error) {
          alert(error);
          return [];
        });
    },

    getPosition($node) {
      let offset = {
        top: 0,
        left: 0,
      };
      while ($node) {
        offset.top += $node.offsetTop;
        offset.left += $node.offsetLeft;
        $node = $node.offsetParent;
      }
      return offset;
    },

    /**
     * avoid re-highlighting the existing selection
     */
    getIds(highlighter, selected) {
      if (!selected || !selected.$node || !selected.$node.parentNode) {
        return [];
      }
      return [
        highlighter.getIdByDom(selected.$node.parentNode),
        highlighter.getExtraIdByDom(selected.$node.parentNode),
      ].filter((i) => i);
    },

    getIntersection(arrA, arrB) {
      const record = {};
      const intersection = [];
      arrA.forEach((i) => (record[i] = true));
      arrB.forEach(
        (i) => record[i] && intersection.push(i) && (record[i] = false)
      );
      return intersection;
    },

    highlightText() {
      const selection = window.getSelection();
      if (selection.isCollapsed) {
        return;
      }
      highlighter.fromRange(selection.getRangeAt(0));
      window.getSelection().removeAllRanges();
    },

    delHL() {
      const id = this.currentHLId; // stored highlight id
      var userId = localStorage.getItem("userId");
      axios
        .get(axios.defaults.baseURL + "articleTask/deleteHighlight", {
          params: {
            userId: userId,
            highlightId: id,
          },
        })
        .then((ret) => {
          let code = ret.data.status;
          if (code == 1) {
            this.$message.success("删除成功");
            highlighter.remove(id);
            this.delHLbtnDis = true;
            this.currentHLId = "";
            this.currentHLText = "";
            this.currentHLTextName = "";
            this.currentHLTextTime = "";
            this.hlComments = [];
            this.replyment = [];
          } else {
            this.$message.error("删除失败");
          }
        });
    },

    //通过高亮ID从数据库获取所有的评论
    getCommentsByHLid(hlId) {
      hlId = this.currentHLId;
      var userId = localStorage.getItem("userId");
      var authority = sessionStorage.getItem("authority");
      var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "listPingLunByHId", {
          highLightId: hlId,
          authority: authority,
          userId: userId,
          groupId: groupId,
        })
        .then((response) => {
          this.hlComments = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
      return [];
    },
  },

  beforeDestroy() {
    document.documentElement.onclick = function (e) {};
    document.documentElement.oncontextmenu = function (e) {};
    sessionStorage.removeItem("init");
  },
  components: {
    CommentContent,
    TeaChat
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}

.liBackground {
  background: #409eff;
  color: #fff;
}
.rightBox {
  background: #fff;
  padding-bottom: 2em;
  padding-top: 5px;
}
.btnHighlight {
  width: 80px;
  z-index: 1000;
}
.selfContainer img {
  display: block !important;
  width: 50%;
  margin: 0 auto !important;
}
</style>
