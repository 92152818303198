import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/Login.vue'
import Home from '@/components/Home.vue'
import Register from '@/components/Register.vue'
import SelectCourse from '@/components/SelectCourse.vue'
import SIdentify from '@/components/Identify.vue'
import Notice from '@/components/Notice.vue'
import Groups from '@/components/Groups.vue'
import ReadingTask from '@/components/ReadingTask.vue'
import StudyData from '@/components/StudyData.vue'
import GroupData from '@/components/GroupData.vue'
import AllCourse from '@/components/AllCourse.vue'
import Person from '@/components/Person.vue'
import AllReadingTasks from '@/components/AllReadingTasks'
import SeeGroups from '@/components/SeeGroups'
import TeacherHome from '@/components/TeacherHome'
import ScoreReadingTask from '@/components/ScoreReadingTask'
import TeacherCourse from '@/components/TeacherCourse'
import TeacherAllTasks from '@/components/TeacherAllTasks'
import ShowPeople from '@/components/ShowPeople'
import ReadingTaskNull from '@/components/ReadingTaskNull'
import AllStuData from '@/components/AllStuData'
import TeacherData from  '@/components/TeacherData'
Vue.use(VueRouter)
Vue.use(SIdentify)


//push
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

//replace
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}


export default new VueRouter({
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      component: Login
    },
    {
      path: '/home',
      redirect: '/allcourse',
      component: Home,
      children: [
        {
          path: '/selectcourse',
          component: SelectCourse
        },
        {
          path: '/allcourse',
          component: AllCourse
        },
        {
          path: '/notice',
          component: Notice
        },
        {
          path: '/groups',
          component: Groups
        },
        {
          path: '/readingtask',
          component: ReadingTask
        },
        {
          path: '/studydata',
          component: StudyData
        },
        {
          path: '/groupdata',
          component: GroupData
        },
        {
          path: '/person',
          component: Person
        },
        {
          path: '/allreadingtasks',
          component: AllReadingTasks
        },
        {
          path: '/readingtasknull',
          component: ReadingTaskNull
        }
      ]
    },
    {
      path: '/register',
      component: Register
    },
    {
      path: '/teacherhome',
      redirect: '/teachercourse',
      component: TeacherHome,
      children: [
        {
          path: '/seegroups',
          component: SeeGroups
        },
        {
          path: '/teachercourse',
          component: TeacherCourse
        },
        {
          path: '/scorereadingtask',
          component: ScoreReadingTask
        },
        {
          path: '/allstudentData',
          component: AllStuData
        },
        {
          path: '/teacheralltasks',
          component: TeacherAllTasks
        },
        {
          path: '/showpeople',
          component: ShowPeople
        },
        {
          path: '/readingtasknull',
          component: ReadingTaskNull
        },
	{
          path: '/teacherData',
          component: TeacherData
        },
      ],
    }
  ]
})