<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/teachercourse' }"
        >首页</el-breadcrumb-item
      > -->
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>所有课程</el-breadcrumb-item>    
    </el-breadcrumb>
    <el-card>
      <el-table :data="array" :row-style="setRowStyle">
        <el-table-column label="序号" prop="courseId"></el-table-column>
        <el-table-column label="课程名" prop="courseName"></el-table-column>
        <el-table-column label="开始时间" prop="startTime"></el-table-column>
        <el-table-column label="结束时间" prop="endTime"></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >查看任务</el-button
            >
            <el-button size="mini" @click="seeGroups(scope.$index, scope.row)"
              >查看分组</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      array: [],
      nowDate:""
    };
  },
  mounted() {
    this.currentTime();
    var teacherId = localStorage.getItem("userId");
    axios
      .post(axios.defaults.baseURL + "findCourseByTId", {
        teacherId: teacherId,
      })
      .then((res) => {
        this.array = res.data.data;
      });
  },
  methods: {
    handleEdit(index, row) {
      axios
        .post(axios.defaults.baseURL + "taskList", {
          courseId: row.courseId,
        })
        .then((res) => {
          this.$router.push("/teacheralltasks");
          sessionStorage.setItem("courseId", row.courseId);
        });
    },
    seeGroups(index, row) {
      sessionStorage.setItem("courseId", row.courseId);
      this.$router.push("/seegroups");
    },
    currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位,则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位,则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位,则前面补零
      this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second}`;
    },
    timecha(beginTime, endTime) {
      var dateBegin = new Date(beginTime);
      var dateEnd = new Date(endTime);
      var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      this.seconds = seconds;
      this.minutes = minutes;
      return leave1
    },
    setRowStyle(row) {
      // console.log(this.nowDate,row.row.endTime)
      let stylejson={}
      // console.log("时间差"+this.timecha(this.nowDate,row.row.endTime))
      if ((this.timecha(this.nowDate,row.row.endTime))<0) {
        // return 'color:red;'
        stylejson.color='red'
        return stylejson
      }
    },
  },
};
</script>
