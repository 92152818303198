<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/teachercourse' }"
        >首页</el-breadcrumb-item
      > -->
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>教师数据</el-breadcrumb-item>    
    </el-breadcrumb>
    <el-card>
      <el-table :data="array" :row-style="setRowStyle">
        <el-table-column label="序号" prop="courseId"></el-table-column>
        <el-table-column label="课程名" prop="courseName"></el-table-column>
        <el-table-column label="学生人数" prop="studentSum"></el-table-column>
        <el-table-column label="分组个数" prop="groupSum"></el-table-column>
        <el-table-column label="任务总数" prop="taskSum"></el-table-column>
 
      </el-table>
    </el-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      array: [],
      nowDate:""
    };
  },
  mounted() {
    this.currentTime();

    var teacherId = localStorage.getItem("userId");
    axios
      .post(axios.defaults.baseURL + "findCourseByTId", {
        teacherId: teacherId,
      })
      .then((res) => {
        this.array = res.data.data;
        console.log(this.array) 
        this.array.forEach(jsonObj => {
        const id = jsonObj.courseId; 
        console.log(id);
        fetch(axios.defaults.baseURL +`StudentSumByCourse?courseId=${id}`)
        .then(response => response.json())
          .then(data => {
    // 在这里将查询到的数据添加到 JSON 对象的新数据列中
          jsonObj.studentSum = data;
          console.log(data);
          }, )
        
        fetch(axios.defaults.baseURL +`GroupSumByCourse?courseId=${id}`)
        .then(response => response.json())
          .then(data => {
    // 在这里将查询到的数据添加到 JSON 对象的新数据列中
          jsonObj.groupSum = data;
          console.log(data);
        },)

        fetch(axios.defaults.baseURL +`TaskSumByCourse?courseId=${id}`)
        .then(response => response.json())
          .then(data => {
    // 在这里将查询到的数据添加到 JSON 对象的新数据列中
          jsonObj.taskSum = data;
          console.log(data);
        },)
        }) 
  })
},
  methods: {

    currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位,则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位,则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位,则前面补零
      this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second}`;
    },
    //日志函数 
    logBehavior(val){
      //localStorage.getItem("userId");
    },
    //根据课程id删除课程
    deleteRow(index, rows) {
      axios
        .post(axios.defaults.baseURL + "moveCourse", {
          id: rows[index].id,
        })
        .then((Response) => {
          if (Response.data.status == 1) {
            this.$message.success("删除成功");
            rows.splice(index, 1);
            this.tableData = rows;
          }
        });
    },
    reading(id) {
      sessionStorage.setItem("courseId", id);
      this.$router.push("/allreadingtasks");
    },
    timecha(beginTime, endTime) {
      var dateBegin = new Date(beginTime);
      var dateEnd = new Date(endTime);
      var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      this.seconds = seconds;
      this.minutes = minutes;
      return leave1
    },
    setRowStyle(row) {
      // console.log(this.nowDate,row.row.endTime)
      let stylejson={}
      // console.log("时间差"+this.timecha(this.nowDate,row.row.endTime))
      if ((this.timecha(this.nowDate,row.row.endTime))<0) {
        // return 'color:red;'
        stylejson.color='red'
        return stylejson
      }
    },
      },}

  
</script>
