<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/allcourse' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>选择课程</el-breadcrumb-item>
      <el-breadcrumb-item>已选课程</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card>
      <el-table :data="tableData" style="width: 100%" :row-style="setRowStyle">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="课程号">
                <span>{{ props.row.courseId }}</span>
              </el-form-item>
              <el-form-item label="课程名">
                <span>{{ props.row.courseName }}</span>
              </el-form-item>
              <el-form-item label="开始时间">
                <span>{{ props.row.startTime }}</span>
              </el-form-item>
              <el-form-item label="结束时间">
                <span>{{ props.row.endTime }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="课程号" prop="courseId"> </el-table-column>
        <el-table-column label="课程名" prop="courseName"></el-table-column>
        <el-table-column label="开始时间" prop="startTime"> </el-table-column>
        <el-table-column label="结束时间" prop="endTime"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <!-- <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="reading(scope.row.courseId);logbehavior('阅读课程按钮')"
            >
              阅读
            </el-button>
            <el-button
              @click.native.prevent="deleteRow(scope.$index, tableData);logbehavior('删除课程按钮')"
              type="text"
              size="small"
            >
              删除
            </el-button>
          </template> -->
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="reading(scope.row.courseId);"
            >
              阅读
            </el-button>
            <el-button
              @click.native.prevent="deleteRow(scope.$index, tableData);"
              type="text"
              size="small"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<style>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>

<script>
import axios from "axios";
export default {
  data() {
    return {
      tableData: [],
      delid: "",
      nowDate:""
    };
  },
  mounted() {
    this.currentTime();
    // 已选课程 axios localStorage.getItem("userId");

    var userId = localStorage.getItem("userId");
    //显示已选课程
    axios
      .post(axios.defaults.baseURL + "listAllCourseByUId", {
        userId: userId,
      })
      .then((Response) => {
        this.tableData = Response.data.data;
      });
  },
  methods: {
    currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位,则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位,则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位,则前面补零
      this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second}`;
    },
    //日志函数 
    logBehavior(val){
      //localStorage.getItem("userId");
    },
    //根据课程id删除课程
    deleteRow(index, rows) {
      axios
        .post(axios.defaults.baseURL + "moveCourse", {
          id: rows[index].id,
        })
        .then((Response) => {
          if (Response.data.status == 1) {
            this.$message.success("删除成功");
            rows.splice(index, 1);
            this.tableData = rows;
          }
        });
    },
    reading(id) {
      sessionStorage.setItem("courseId", id);
      this.$router.push("/allreadingtasks");
    },
    timecha(beginTime, endTime) {
      var dateBegin = new Date(beginTime);
      var dateEnd = new Date(endTime);
      var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      this.seconds = seconds;
      this.minutes = minutes;
      return leave1
    },
    setRowStyle(row) {
      // console.log(this.nowDate,row.row.endTime)
      let stylejson={}
      // console.log("时间差"+this.timecha(this.nowDate,row.row.endTime))
      if ((this.timecha(this.nowDate,row.row.endTime))<0) {
        // return 'color:red;'
        // stylejson.display='none'
        return stylejson
      }
    },
  },
};
</script>

