<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/teachercourse' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>查看分组</el-breadcrumb-item>    
    </el-breadcrumb>
    <el-card>
      <el-table :data="array">
        <el-table-column label="课程号" prop="courseId"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="小组人数" prop="currentNum"></el-table-column>
        <el-table-column label="小组号" prop="groupId"></el-table-column>
        <el-table-column label="小组名词" prop="groupName"></el-table-column>>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >成员</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      array: [],
    };
  },
  mounted() {
    //列出课程所有小组
    var courseId = sessionStorage.getItem("courseId");
    axios
      .post(axios.defaults.baseURL + "listAllGroup", {
        courseId: courseId,
      })
      .then((res) => {
        this.array = res.data.data;
      });
  },
  methods: {
    handleEdit(index, row) {
      sessionStorage.setItem("groupId", row.groupId);
      this.$router.push("/showpeople");
    },
  },
};
</script>
