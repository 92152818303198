<script>

/* 限时任务 群通知消息*/

export default {
  name: "lemonMessageNotice",
  inheritAttrs: false,
  inject: ["IMUI"],
  render() {
    return (
      <lemon-message-basic
        class="lemon-message-notice"
        props={{ ...this.$attrs }}
        scopedSlots={{
          content: props => {
            return <el-card class="box-card">
                    <div slot="header" class="clearfix">
                      <span style="font-weight:bold">{props.content.title}</span>
                    </div>
                    <div class="text item">{props.content.introduction}</div>
                    <div class="text item" style="font-size:13px;color:#aaa">截止时间：</div>
                    <div class="text item" style="font-size:13px;color:#aaa">{props.content.date1 + ' ' +props.content.date2}</div>
                    <el-button style="width:100%;margin-top:10px">{JSON.parse(localStorage.getItem('receivedNotice')).indexOf(props.id)==-1?"确认收到":'已收到'}</el-button>
                   </el-card>;
          }
        }}
      />
    );
  }
};
</script>
<style lang="less" scoped>
.lemon-message.lemon-message-notice {
  user-select: none;
}
.lemon-message.lemon-message-notice .lemon-message__content {
  border: 2px solid #000;
  font-size: 12px;
  cursor: pointer;
}
.lemon-message.lemon-message-notice .lemon-message__content::before {
  display: none;
}
</style>
