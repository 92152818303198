import axios from 'axios';
class LocalStore {
    constructor(id) {
        this.key = id !== undefined ? `highlight-mengshou-${id}` : 'highlight-mengshou';
    }

    jsonToStore(stores) {
        localStorage.setItem(this.key, JSON.stringify(stores));// 改为 调用api 存入后台
    }

    save(authority, highlightInfo) {
        var articleId = sessionStorage.getItem("readingtaskId");
        var groupId = localStorage.getItem("groupId");
        var userId = localStorage.getItem("userId");
        axios.post(axios.defaults.baseURL + "articleTask/insertHighlight", {
            articleId: articleId,
            groupId: groupId,
            userId: userId,
            currentAuthority: authority,
            highlightInfo
        }).then(ret => {
            console.log('data submitted')
        })

    }

    forceSave(store) {
        const stores = this.storeToJson();
        stores.push(store);
        this.jsonToStore(stores);
    }

    // remove(id) {
    //     var userId = localStorage.getItem("userId");
    //     axios.get(axios.defaults.baseURL + "articleTask/deleteHighlight", {
    //         params: {
    //             userId: userId,
    //             highlightId: id
    //         }
    //     }).then(ret => {
    //         let code = ret.data.status;
    //         return code;
    //         //sessionStorage.setItem("code",code);
    //     })
    // }

    getAll() {
        return this.storeToJson();
    }

    removeAll() {
        this.jsonToStore([]);
    }
}

export default LocalStore;