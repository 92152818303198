export default [
  {
    id: 1,
    displayName: "像梦一样自由",
    avatar: "https://p.qqan.com/up/2020-2/2020022821001845128.jpg",
    index: "X",
    unread: 0,
    lastSendTime: 1566047865417,
    lastContent: "你开心吗",
  },
  {
    id: 2,
    displayName: "梦醒时分、",
    avatar: "https://p.qqan.com/up/2021-1/20211301122243621.jpg",
    index: "M",
    unread: 0,
    lastSendTime: 1566047865417,
    lastContent: "",
  },
  {
    id: 3,
    displayName: "凌云",
    avatar: "https://p.qqan.com/up/2021-1/2021129102387841.jpg",
    index: "L",
    unread: 0,
    lastSendTime: 1566047865417,
    lastContent: "",
  },
  {
    id: 4,
    displayName: "小郭",
    avatar: "https://p.qqan.com/up/2021-1/2021122135507881.jpg",
    index: "X",
    unread: 0,
    lastSendTime: 1566047865417,
    lastContent: "",
  },
  {
    id: 5,
    displayName: "杨玉泉",
    avatar: "https://p.qqan.com/up/2021-1/20211211131598147.jpg",
    index: "Y",
    unread: 0,
    lastSendTime: 1566047865417,
    lastContent: "",
  },
  {
    id: 6,
    displayName: "森系Style",
    avatar: "https://p.qqan.com/up/2021-1/2021113104111220.jpg",
    index: "S",
    unread: 0,
    lastSendTime: 1566047865417,
    lastContent: "",
  },
  {
    id: 7,
    displayName: "霸王花",
    avatar: "https://p.qqan.com/up/2021-1/20211411391666.jpg",
    index: "B",
    unread: 0,
    lastSendTime: 1566047865417,
    lastContent: "你怎么还不睡呀？",
  },
  {
    id: 8,
    displayName: "曾平",
    avatar: "https://p.qqan.com/up/2020-12/202012291044425822.jpg",
    index: "Z",
    unread: 0,
    lastSendTime: 1566047865417,
    lastContent: "",
  },
  {
    id: 9,
    displayName: "淡然",
    avatar: "https://p.qqan.com/up/2020-12/202012141813343503.jpg",
    index: "D",
    unread: 0,
    lastSendTime: 1566047865417,
    lastContent: "",
  },
  {
    id: 10,
    displayName: "叶子。",
    avatar: "https://p.qqan.com/up/2021-1/20211301122243621.jpg",
    index: "Y",
    unread: 0,
    lastSendTime: 1566047865417,
    lastContent: "",
  },
  {
    id: 11,
    displayName: "土豆",
    avatar: "https://p.qqan.com/up/2020-12/202012111157268739.jpg",
    index: "T",
    unread: 0,
    lastSendTime: 1566047865417,
    lastContent: "",
  },
  {
    id: 12,
    displayName: "清沫",
    avatar: "https://p.qqan.com/up/2020-12/202012415467996.jpg",
    index: "Q",
    unread: 0,
    lastSendTime: 1566047865417,
    lastContent: "",
  },
  {
    id: 13,
    displayName: "Lemon-imui交流群",
    avatar: "https://p.qqan.com/up/2020-11/20201127157109035.jpg",
    index: "L",
    isGroup: true,
    unread: 0,
    lastSendTime: 1566047865417,
    lastContent: "这个咋处理啊？",
  },
  {
    id: 14,
    displayName: "系统通知",
    avatar: "https://p.qqan.com/up/2020-6/2020061117234279854.jpg",
    index: "[1]系統通知",
    unread: 0,
    lastSendTime: 1566047865417,
    lastContent: "宁静致远通过了你的好友请求",
    renderContainer() {
      return (
        <div style="padding:15px;">
          <div>宁静致远通过了你的好友请求</div>
          <div>宁静致远通过了你的好友请求</div>
          <div>宁静致远通过了你的好友请求</div>
        </div>
      );
    },
  },
  {
    id: 15,
    displayName: "宁静致远。",
    avatar: "https://p.qqan.com/up/2020-6/2020060308522797777.jpg",
    index: "N",
    unread: 0,
    lastSendTime: 1566047865417,
    lastContent: "",
  },
];
