<template>
  <div>
    <!--面包屑导航区域-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/allcourse' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/allReadingTasks' }">所有任务</el-breadcrumb-item>
      <el-breadcrumb-item>小组学习数据</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="20">
      <el-col :span="6" v-for="o in cardLists" :key="o.title">
        <el-card :body-style="{ padding:'0px'}">
            <div slot="header" class="card-header" style="height: 30px;">
              <span class="card-title"  style="font-size: 20px; 
                font-weight: bold;">
                <span style="float: left;">{{ o.title }}</span> 
                <span style="float: right;"><img :src="o.imgSrc" alt="图像" style="width: 30px; height: 30px;">
                  </span>
              </span> 
            </div>
          <div class="card-content">
            <div class="card-row">
              <span 
                class="card-data-value" 
                style="float: right; 
                margin-right: 20px;
                padding-top: 10px;
                padding-bottom: 10px;"

                >{{ o.number }}</span>
            </div>                <!-- font-size: 20px; 
                font-weight: bold; -->
            <div class="bottom clearfix">
            </div>
          </div>

        </el-card>
        <div style="padding: 14px;"> </div>
      </el-col>
    </el-row>


  <el-card>
    <div style="display: flex;">
    <!-- 右侧部分 -->
    <div style="flex: 1; padding: 10px;display: flex; flex-direction: column; align-items: center;">
      <!-- 右侧内容 -->
      <div style="margin-bottom: 20px; font-weight: bold; font-size: 24px;">批注词云图</div>
      <button @click="get_student_WordCloud">词云图</button>
      <img :src="wordcloud" style="max-width: 500px; max-height: 500px; width: 100%; height: auto;" /> 
    </div>
    
    
    <!-- 左侧部分 -->
    <div style="flex: 1; display: flex; flex-direction: column; align-items: center;">
      <!-- 左侧内容 -->
      <div style="margin-bottom: 20px; font-weight: bold; font-size: 24px;">周学情统计图</div>
      <div id="main" style="width: 700px; height: 500px"></div>
    </div>
  </div>
</el-card>
  </div>
</template>

<script>
import axios from 'axios';
// const wordcloudUrl = 'http://localhost:5000';
const wordcloudUrl = 'http://47.99.140.190:5001';
  export default{
    data(){
        return{
          cardLists: [
        { title: '高亮次数', number: '', imgSrc: require('@/assets/pic/tap.svg') },  
        { title: '批注次数', number: '', imgSrc: require('@/assets/pic/message-comments.svg') },
        { title: '批注长度', number: '', imgSrc: require('@/assets/pic/message-comments.svg') },
        { title: '提问次数', number: '', imgSrc: require('@/assets/pic/help.svg') },
        { title: '回复次数', number: '', imgSrc: require('@/assets/pic/message-reply.svg') },
        { title: '点赞次数', number: '', imgSrc: require('@/assets/pic/good.svg') },
      ],    
      // cardLists: [
      //   { title: '课程数目', number: '' },
      //   { title: '点赞次数', number: '' },
      //   { title: '评论次数', number: '' },
      //   { title: '批注次数', number: '' },
      //   { title: '提问次数', number: '' },
      //   { title: '回复次数', number: '' }
      // ], 
            wordcloud : ''
        }
    },
  created() {},
  // 此时页面上的元素已经被渲染完毕
  mounted() {
    var userId = localStorage.getItem("userId");
    var groupId = localStorage.getItem("groupId");
    var articleId = sessionStorage.getItem("readingtaskId");
    console.log(`output->`,articleId)
    // 学习数据 axios localStorage.getItem("userId");
    axios.get(axios.defaults.baseURL+"ZanSumByGid",{
      params:{
        groupId:groupId,
        articleId:articleId
      }
    }).then(Response=>{
      // this.cardLists[0].number=Response.data;
      this.cardLists[5].number=`${Response.data}次`
    })

    axios.get(axios.defaults.baseURL+"PingLunSumByGid",{
      params:{
        groupId:groupId,
        articleId:articleId
      }
    }).then(Response=>{
      // this.cardLists[0].number=Response.data;
      this.cardLists[1].number=`${Response.data}次`
    })
    axios.get(axios.defaults.baseURL+"articleTask/HighlightSumByGid",{
      params:{
        groupId:groupId,
        articleId:articleId
      }
    }).then(Response=>{
      // this.cardLists[0].number=Response.data;
      console.log(Response.data);
      this.cardLists[0].number=`${Response.data}次`
    })

    axios.get(axios.defaults.baseURL+"PingLunLengthByGid",{
      params:{
        groupId:groupId,
        articleId:articleId
      }
    }).then(Response=>{
      // this.cardLists[0].number=Response.data;
      console.log(Response.data);
      this.cardLists[2].number=`${Response.data}字`
    })

    axios.get(axios.defaults.baseURL+"QuestionSumByGid",{
      params:{
        groupId:groupId,
        articleId:articleId
      }
    }).then(Response=>{
      // this.cardLists[0].number=Response.data;
      console.log(Response.data);
      this.cardLists[3].number=`${Response.data}次`
    })
    axios.get(axios.defaults.baseURL+"huiFuCountByGId",{
      params:{
        groupId:groupId,
        articleId:articleId
      }
    }).then(Response=>{
      // this.cardLists[0].number=Response.data;
      console.log(Response.data);
      this.cardLists[4].number=`${Response.data}次`
    })

    // 3. 基于准备好的dom, 初始化echarts实例
    var myChart = echarts.init(document.getElementById("main"));

    // 4. 准备数据和配置项
    var option = {
      xAxis: {
        type: "category",
        data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: [150, 230, 224, 218, 135, 147, 260],
          type: "line",
        },
      ],
    };

    // 5. 展示数据
    myChart.setOption(option);
  },
    methods:{
        get_student_WordCloud(){
            var read_value = sessionStorage.getItem("readingtaskId");
            var group_value =localStorage.getItem("groupId");
            axios.post(wordcloudUrl+'/student_getImage',{
                task_id : read_value,
                groupId : group_value
            }).then(response => {
              console.log(response)
              this.wordcloud = 'data:image/png;base64,'+ response.data.wordcloud;
            });
        }
    }
  }
</script>

<style lang="less" scoped>
</style>

