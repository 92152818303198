<template>
  <div>
    <!--面包屑导航区域-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/teachercourse' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/TeacherAllTasks' }">所有任务</el-breadcrumb-item>
      <el-breadcrumb-item>全部学生数据</el-breadcrumb-item>
    </el-breadcrumb>

    

    <!-- 任务概要信息区域 -->

    <div style="display: flex;
      justify-content: center; /* 水平居中 */">
      <div class="task" style="width: 60%; text-align: center; display: flex; flex-direction: column;  padding: 20px; border-radius: 10px;/* 水平和垂直居中 */">
        <h1 class="task-name">{{ taskName }}</h1>
        <div class="progress-bar">
          <div class="progress" ref="progress"></div>
          <div style="text-align: right; font-size: 18px; color: #666;">
      注：蓝色表示进行中，灰色表示已结束
          </div>
        </div>
      </div>
    </div>
    

    <div class="task-summary">
      <span :class="'completed'"></span>
      <div class="student-group-count" style="text-align: left; font-size: 20px;">
        <span class="label">学生个数:</span>
        <span class="count" style="margin-right: 20px;">{{ studentCount }}</span>
        <span class="label">小组个数:</span>
        <span class="count">{{ groupCount }}</span>
      </div>
    </div>

    <el-row :gutter="20">
      <el-col :span="6" v-for="o in cardLists" :key="o.title">
        <el-card :body-style="{ padding:'0px'}">
          <div slot="header" class="card-header" style="height: 30px;">
            <span class="card-title"  style="font-size: 20px; font-weight: bold;">
              <span style=" float: left;">{{ o.title }}</span> 
              <span style="float: right;"><img :src="o.imgSrc" alt="图像" style="width: 30px; height: 30px;"></span>
            </span> 
          </div>
          <div class="card-data">
            <div style="margin-bottom: 10px;"></div>
            <!-- 第一行 -->
            <div class="card-data-row">
              <div style="display: flex; justify-content: space-between;">
                <span class="card-data-title" style="text-align: center; margin-left: 50px; font-size: 20px; flex-grow: 1;">总数</span>
                <span class="card-data-value" style="width: 50px; margin-right: 25px; font-size: 20px; text-align: right;">{{ o.sum_num }}</span>
              </div>
            </div>

            <div style="margin-bottom: 10px;"></div>
            <!-- 第二行 -->
            <div class="card-data-row">
              <div style="display: flex; justify-content: space-between;">
                <span class="card-data-title" style="text-align: center; margin-left: 50px; font-size: 20px; flex-grow: 1;">人均</span>
                <span class="card-data-value" style="width: 50px; margin-right: 25px; font-size: 20px; text-align: right;">{{ o.ave_num }}</span>
              </div>
            </div>
            <div style="margin-bottom: 10px;"></div>
          </div>

            <!-- font-size: 20px; 
            font-weight: bold; -->
          <div class="bottom clearfix"></div>
        </el-card>
        <div style="padding: 14px;"></div>
      </el-col>
    </el-row>

    <div class="button-and-div-container" style="display: flex; align-items: center;">
      <div style="width: 34%; margin: auto;">
        <div style="margin: 10px; display: flex; font-size: 20px; flex-grow: 1; font-weight: bold; justify-content: center;">批注词云图</div>
        <button @click="get_teacher_WordCloud" style="text-align: center;">词云图</button>
        <img :src="wordcloud" style="max-width: 500px; max-height: 500px; width: 100%; height: auto;" />
      </div>
      <div class="chart-container" style="display: flex; margin: 10px; flex-direction: column; width: 22%;">
        <div class="chart-title" style="margin: 10px; display: flex; font-weight: bold; font-size: 20px; flex-grow: 1; justify-content: center;">高亮次数排行榜</div>
        <div class="chart-content">
          <div id="pizhubar" style="width: 100%; height: 400px;"></div>
        </div>
      </div>

      <div class="chart-container" style="margin: 10px; display: flex; flex-direction: column; width: 22%;">
        <div class="chart-title" style="margin: 10px; display: flex; font-size: 20px; flex-grow: 1; font-weight: bold; justify-content: center;">批注次数排行榜</div>
        <div class="chart-content">
          <div id="pinglunbar" style="width: 100%; height: 400px;"></div>
        </div>
      </div>

      <div class="chart-container" style="margin: 10px; display: flex; flex-direction: column; width: 22%;">
        <div class="chart-title" style="margin: 10px; display: flex; font-size: 20px; flex-grow: 1; font-weight: bold; justify-content: center;">批注长度排行榜</div>
        <div class="chart-content">
          <div id="pinglunlengthbar" style="width: 100%; height: 400px;"></div>
        </div>
      </div>
    </div>

  </div>
</template>
  
<script>
import axios from 'axios';
import * as echarts from 'echarts'
import user from '../plugins/database/user';
// const wordcloudUrl = 'http://localhost:5000';
const wordcloudUrl = 'http://47.99.140.190:5001';
  export default{
    data(){
        return{
          taskName: '', // 任务名称
          taskStatus: '', // 任务状态
          currentTime : '',
          endTime : '',
          studentCount: '', // 学生个数（示例数据）
          groupCount: '', // 小组个数（示例数据）
          cardLists: [
            { title: '高亮次数', sum_num: '', ave_num: '', imgSrc: require('@/assets/pic/tap.svg') },
            { title: '批注次数', sum_num: '', ave_num: '', imgSrc: require('@/assets/pic/message-comments.svg') },
            { title: '批注长度', sum_num: '', ave_num: '', imgSrc: require('@/assets/pic/message-comments.svg') },
            { title: '提问次数', sum_num: '', ave_num: '', imgSrc: require('@/assets/pic/help.svg') },
            { title: '回复次数', sum_num: '', ave_num: '', imgSrc: require('@/assets/pic/message-reply.svg') },
            { title: '点赞次数', sum_num: '', ave_num: '', imgSrc: require('@/assets/pic/good.svg') },
          ], 
          xValue1: [],
          yValue1: [], 
          xValue2: [],
          yValue2: [],
          xValue3: [],
          yValue3: [],
          wordcloud : ''
        }
    },
    mounted() {
      var courseId = sessionStorage.getItem("courseId");
      var articleId = parseInt(sessionStorage.getItem("readingtaskId"), 10);
      
      const progressElement = document.querySelector('.progress');
      progressElement.style.width = '100%';

      axios.get(axios.defaults.baseURL+"GroupSumByCourse",{
        params:{
          courseId:courseId
        }
      }).then(Response=>{
        this.groupCount = Response.data;
      })

      axios.get(axios.defaults.baseURL+"findTaskById",{
        params:{
          articleId:articleId
        }
      }).then(Response=>{
        this.taskName = Response.data.data.title;
        this.startTime = Response.data.data.startTime;
        this.endTime = Response.data.data.endTime;
        this.calculateTaskStatus();
        console.log(this.endTime)
      })

      this.fetchData(courseId, articleId);

      axios.get(axios.defaults.baseURL+"articleTask/countByArticle",{
        params:{
          articleId:articleId
        }
      }).then(Response=>{
        console.log(Response.data.data)
        var data = JSON.parse(Response.data.data);
        console.log(typeof Response.data.data);
        var x1 = [];
        var y1 = [];
        // 遍历 responseData，提取 count 值并添加到 x 中
        data.forEach(item => {
          x1.push(item.count);
          y1.push(item.username);
        });
        this.xValue1= x1;
        this.yValue1= y1;
        if (this.xValue1.length < 6) {
          const missingValues = 6 - this.xValue1.length;
          for (let i = 0; i < missingValues; i++) {
            this.xValue1.push(0);
            this.yValue1.push("无");
          }
        }
        // 反转 xValue1 数组
        this.xValue1.reverse();
        this.yValue1.reverse();
        this.drawChart('pizhubar', '高亮次数排行榜', this.xValue1, this.yValue1);
      }
      )
      
      axios.get(axios.defaults.baseURL+"countTop6PLUser",{
        params:{
          articleId:articleId
        }
      }).then(Response=>{
        console.log(Response.data.data)
        var data = JSON.parse(Response.data.data);
        console.log(typeof Response.data.data);
        var x2 = [];
        var y2 = [];
        // 遍历 responseData，提取 count 值并添加到 x 中
        data.forEach(item => {
          x2.push(item.count);
          y2.push(item.username);
        });
        this.xValue2= x2;
        this.yValue2= y2;
        if (this.xValue2.length < 6) {
          const missingValues = 6 - this.xValue2.length;
          for (let i = 0; i < missingValues; i++) {
            this.xValue2.push(0);
            this.yValue2.push("无");
          }
        }
        // 反转 xValue1 数组
        this.xValue2.reverse();
        this.yValue2.reverse();
        this.drawChart('pinglunbar', '评论次数排行榜', this.xValue2, this.yValue2);
      }
      )

      axios.get(axios.defaults.baseURL+"lengthTop6PLUser",{
        params:{
          articleId:articleId
        }
      }).then(Response=>{
        console.log(Response.data.data)
        var data = JSON.parse(Response.data.data);
        console.log(typeof Response.data.data);
        var x3 = [];
        var y3 = [];
        // 遍历 responseData，提取 count 值并添加到 x 中
        data.forEach(item => {
          x3.push(item.lengthCount);
          y3.push(item.username);
        });
        this.xValue3= x3;
        this.yValue3= y3;
        if (this.xValue3.length < 6) {
          const missingValues = 6 - this.xValue3.length;
          for (let i = 0; i < missingValues; i++) {
            this.xValue3.push(0);
            this.yValue3.push("无");
          }
        }
        // 反转 xValue3 数组
        this.xValue3.reverse();
        this.yValue3.reverse();
        this.drawChart('pinglunlengthbar', '评论长度排行榜', this.xValue3, this.yValue3);
      }
      )
      
    },
    methods:{
      calculateTaskStatus() {
            const currentTime = new Date();
            const endTime = new Date(this.endTime);
            const progress = this.$refs.progress;

      console.log(`output->`,endTime)
            if (currentTime < endTime) {
              progress.style.backgroundColor = '#2196F3'; // 未完成，蓝色
            } else {
              progress.style.backgroundColor = '#555'; // 已完成，灰色
            }
    },


      fetchData(courseId, articleId) {
        axios.get(axios.defaults.baseURL + "StudentSumByCourse", {
          params: {
            courseId: courseId
          }
        })
        .then((response) => {
          this.studentCount = response.data;

          return axios.get(axios.defaults.baseURL + "articleTask/countByArticleId", {
            params: {
              articleId: articleId
            }
          });
        })
        .then((response) => {
          this.cardLists[0].sum_num = response.data;
          console.log(`output->`,this.cardLists[0].sum_num)
          console.log(`output->`,this.studentCount)
          this.cardLists[0].ave_num = (this.cardLists[0].sum_num / Number(this.studentCount)).toFixed(1);

          // 继续下一个请求
          return axios.get(axios.defaults.baseURL + "PingLunByAid", {
            // 参数配置
            params:{
              articleId: articleId
            }
          });
        })
        .then((nextApiResponse) => {
          // 处理下一个请求的响应
          this.cardLists[1].sum_num = nextApiResponse.data;
          this.cardLists[1].ave_num = (this.cardLists[1].sum_num / Number(this.studentCount)).toFixed(1);
          // 继续下一个请求
          return axios.get(axios.defaults.baseURL + "PingLunLengthByAid", {
            // 参数配置
            params:{
              articleId: articleId
            }
          });
        })
        .then((anotherApiResponse) => {
          this.cardLists[2].sum_num = anotherApiResponse.data;
          this.cardLists[2].ave_num = (this.cardLists[2].sum_num / Number(this.studentCount)).toFixed(1);
          return axios.get(axios.defaults.baseURL + "questionCountByAId", {
            // 参数配置
            params:{
              articleId: articleId
            }
          });
        })
        .then((Response4) => {
          this.cardLists[3].sum_num = Response4.data;
          this.cardLists[3].ave_num = (this.cardLists[3].sum_num / this.studentCount).toFixed(1);
          return axios.get(axios.defaults.baseURL + "huiFuCountByAId", {
            // 参数配置
            params:{
              articleId: articleId
            }
          });
        })
        .then((Response4) => {
          this.cardLists[4].sum_num = Response4.data;
          this.cardLists[4].ave_num = (this.cardLists[4].sum_num / this.studentCount).toFixed(1);
          return axios.get(axios.defaults.baseURL + "zanCountByAId", {
            // 参数配置
            params:{
              articleId: articleId
            }
          });
        })
        .then((Response5) => {
          this.cardLists[5].sum_num = Response5.data;
          this.cardLists[5].ave_num = (this.cardLists[5].sum_num / this.studentCount).toFixed(1);
        })
        .catch((error) => {
          console.error("请求出错: " + error);
        });
      },
      drawChart(containerId, chartName, xValue, yValue) {
        const chart = echarts.init(document.getElementById(containerId));
        const option = {
          color: ['#d84430'],
          tooltip: {
            show: true
          },
          yAxis: {
            axisTick: {
              show: false
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              inside: true,
              verticalAlign: 'bottom',
              lineHeight: 40,
              color: '#ddDFEB',
              formatter: function (value, index) {   // 设置y轴文字的颜色
                if (index > 2) {
                  return '{first|' + value + '}'
                } else {
                  return '{other|' + value + '}'
                }
              },
              rich: {
                other: {
                  // color: '#DDDFEB',
                  color: '#333',
                  opacity: 0.57
                },
                first: {
                  color: '#333'
                }
              }
            },
            data: yValue
          },
          xAxis: {
            nameTextStyle: {
              color: 'rgba(0, 255, 255, 0.8)',
              align: 'right'
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              color: 'rgba(255, 255, 255, 0.8)'
            },
          },
          grid: {
            top: '0%',
            bottom: '0%',
            left: '0%',
            right: '5%'
          },
          series: [{
            name: '批注次数排行榜',
            barWidth: 15,
            type: 'bar',
            data: xValue,
            itemStyle: {
              normal: {
                borderRadius: [3, 20, 20, 3],
                color: function (params) {   // 设置柱形图的颜色
                  if (params.dataIndex === 5) {
                    return '#d84430'
                  } else if (params.dataIndex === 4) {
                    return '#f38237'
                  } else if (params.dataIndex === 3) {
                    return '#e2aa20'
                  } else {
                    return '#608289'
                  }
                }
              },
            },
                    label: {
            normal: {
              show: true, // 显示数据标签
              position: 'right', // 在右侧显示
              formatter: '{c}', // 使用数据项的值作为标签内容
              color: '#333', // 标签颜色
            },
            }  
          }],
        };
        chart.setOption(option);
        window.addEventListener('resize', () => {
        chart.resize()
        });
      },

        get_teacher_WordCloud(){
            var value = sessionStorage.getItem("readingtaskId");
            axios.post(wordcloudUrl+'/teacher_getImage',{
                task_id : value
            }).then(response => {
              console.log(response)
              this.wordcloud = 'data:image/png;base64,'+ response.data.wordcloud;
            });
        }
    },created() {
     // 初始化计算任务状态
  },
  }
</script>
  
<style lang="less" scoped>
    .task-summary {
    text-align: center;
    margin: 20px 0;
  }

  .task-name {
    font-size: 24px;
    font-weight: bold;
  }

  .task-status {
    margin-top: 10px;
  }

  .status-icon {
    display: inline-block;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
    color: #fff;
    font-weight: bold;
  }

  .completed {
    background-color: green;
  }

  .in-progress {
    background-color: yellow;
  }

  .not-started {
    background-color: red;
  }

  .student-group-count {
    margin-top: 10px;
  }

  .label {
    font-weight: bold;
  }

  .count {
    margin-left: 5px;
    font-weight: bold;
  }

  .task {
    margin: 10px;
    border: 1px solid #ddd;
    padding: 10px;
  }

  .progress-bar {
    height: 10px;
    background-color: #f2f2f2;
  }

  .progress {
    height: 100%;
    width: 50%; /* 50% 表示任务完成了一半 */
    background-color: rgb(9, 126, 221);
  }
</style>
  