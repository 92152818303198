import Vue from 'vue'
import { Button, Form, FormItem, Input, Message, Radio, RadioButton, RadioGroup, Container, Header, Aside, Main, Menu, Submenu, MenuItem, MenuItemGroup, BreadcrumbItem, Breadcrumb, Card, Row, Col, Progress, Dialog, Table, TableColumn, Pagination, Divider, Scrollbar, Badge, Transfer, Select, Option, Dropdown, DropdownItem, DropdownMenu, Icon, DatePicker,TimePicker,Rate,Tooltip,Image } from 'element-ui'

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Radio)
Vue.use(RadioButton)
Vue.use(RadioGroup)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(BreadcrumbItem)
Vue.use(Breadcrumb)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Progress)
Vue.use(Dialog)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Divider)
Vue.use(Scrollbar)
Vue.use(Badge)
Vue.use(Transfer)
Vue.use(Select)
Vue.use(Option)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Icon)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Rate)
Vue.use(Tooltip)
Vue.use(Image)

Vue.prototype.$message = Message