<template>
  <div>
    <!-- 高亮按钮 -->
    <el-button id="js-highlight" class="btnHighlight" @click="highlightText"
      >高亮</el-button
    >
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>阅读任务</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 阅读区域 -->
    <div style="display: flex; width: 100%; height: 100%">
      <el-card style="width: 1200px; margin-right: 20px; height: 100%">
        <div slot="header">
          <div style="margin: 20px 0">
            <el-select
              v-model="selected"
              placeholder="请选择小组"
              value-key="groupId"
              @change="selectGroupPerson($event)"
              filterable
              :disabled="selectOne"
              clearable
            >
              <el-option
                v-for="(item, index) in itemArray"
                :key="item.groupId"
                :label="item.groupName"
                :value="item.groupId"
                @click.native="searchGroupHighlight(item.groupId)"
              >
                {{ item.groupName }}
              </el-option>
            </el-select>
            <el-divider direction="vertical"></el-divider>
            <el-select
              v-model="selectedPerson"
              value-key="id"
              placeholder="请选择组员"
              :disabled="selectTwo"
              clearable
            >
              <el-option
                v-for="(item, index) in itemArrayPerson"
                :key="item.id"
                :label="item.username"
                :value="item.id"
                @click.native="searchPerson(item.id)"
              >
                {{ item.username }}
              </el-option>
            </el-select>
            <el-divider direction="vertical"></el-divider>
            <el-select v-model="selectAuth" placeholder="请选择权限">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                @click.native="searchHighlight(item.value)"
              ></el-option>
            </el-select>
          </div>
        </div>

        <div style="height: 600px; width: 100%">
          <el-scrollbar id="scro" style="height: 100%">
            <div
              class="selfContainer"
              id="articleDiv"
              v-html="articleHtml"
            ></div>
          </el-scrollbar>
        </div>
      </el-card>

      <!-- 评论区域 -->
      <el-card style="width: 600px; height: 100%">
        <div style="height: 717px; width: 100%">
          <el-scrollbar style="height: 100%">
            <div id="comment" class="col-md-9 rightBox">
              <div style="margin: 20px 0">
                <h3>高亮文字</h3>
                <p style="font-style: italic; color: #ff915b">
                  {{ currentHLText }}
                </p>
                <p>
                  {{ currentHLTextName }}
                  {{ currentHLTextTime }}
                </p>
                <el-button
                  id="delHLbtn"
                  type="primary"
                  @click="delHL"
                  :disabled="delHLbtnDis"
                  icon="el-icon-delete"
                  size="mini"
                  >删除</el-button
                >
              </div>
              <div >
                <!-- 父子组件数据绑定 -->
                <CommentContent1
                  v-bind:articleId="this.articleId"
                  v-bind:hlId="this.currentHLId"
                  v-bind:comment="this.hlComments"
                  v-bind:commentText="this.currentHLText"
                  v-bind:replyment="this.replyment"
                  @child="father"
                  @childhuifu="fatherhuifu"
                  v-bind:groupId="this.groupId"
                  v-bind:authority="this.authority"
                ></CommentContent1>
                <TeaChat />
              </div>
            </div>
          </el-scrollbar>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import Highlighter from "web-highlighter";
import LocalStore from "../local.store.js";
import axios from "axios";
import CommentContent1 from "./CommentContent1";
import TeaChat from "./TeaChat"
let highlighter = null;
const store = new LocalStore();
const log = console.log.bind(console, "[highlighter]");
sessionStorage.setItem("init", 0);

export default {
  data() {
    return {
      articleId: "",
      articleHtml: "", //所需要高亮评论的文本
      currentHLText: "",
      currentHLTextName: "",
      currentHLTextTime: "",
      currentHLId: "",
      authority: 4, //小组是1，个人是0，公共是2
      delHLbtnDis: true,
      hlComments: [], //所选择高亮的所有评论
      replyment: [], //评论的回复
      selected: "", //小组
      itemArray: [], //小组
      selectedPerson: "", //个人
      itemArrayPerson: [], //个人
      groupId: 0,
      selectAuth: "",
      options: [
        {
          value: "0",
          label: "仅学生自己可见的高亮",
        },
        {
          value: "1",
          label: "仅小组可见的高亮",
        },
        {
          value: "2",
          label: "公开可见的高亮",
        },
        {
          value: "3",
          label: "小组内某个学生的高亮",
        },
      ],
      stuId: 0,
      selectOne: false,
      selectTwo: false,
    };
  },

  //初始化
  mounted() {
    //列出某门课程下的所有小组
    var courseId = sessionStorage.getItem("courseId");
    axios
      .post(axios.defaults.baseURL + "listAllGroup", {
        courseId: courseId,
      })
      .then((response) => {
        this.itemArray = response.data.data;
      });
    //高亮button的根节点设置
    highlighter = new Highlighter({
      $root: document.getElementById("articleDiv"),
      wrapTag: "i",
      exceptSelectors: [".my-remove-tip", "pre", "code"],
    });
    //根据阅读课程ID和阅读任务ID，显示响应的富文本
    var value = sessionStorage.getItem("readingtaskId");
    this.articleId = value;
    if (this.articleId == 0) {
      this.$message.warning("请先选择一项阅读任务");
    } else {
      axios
        .post(axios.defaults.baseURL + "selectContent", {
          id: value,
        })
        .then((response) => {
          let textareaHtml = response.data.data.content;
          if (textareaHtml) {
            this.articleHtml = textareaHtml;
          }
        });
    }

    highlighter.hooks.Serialize.Restore.tap((source) =>
      log("Serialize.Restore hook -", source)
    );

    highlighter.hooks.Serialize.RecordInfo.tap(() => {
      const extraInfo = Math.random().toFixed(4);
      log("Serialize.RecordInfo hook -", extraInfo);
      return extraInfo;
    });

    /**
     * highlighter event listener
     */
    highlighter
      .on(Highlighter.event.CLICK, ({ id }) => {
        var hlDoms = highlighter.getDoms(id);
        const position = this.getPosition(hlDoms);
        this.delHLbtnDis = false;
        this.currentHLId = id;
        sessionStorage.setItem("highlightId", this.currentHLId);
        this.currentHLText = "";
        //设置高亮文字
        for (var i in hlDoms) {
          this.currentHLText += hlDoms[i].innerText;
        }
        //设置高亮相应的评论
        axios
          .get(axios.defaults.baseURL + "articleTask/showHighlight", {
            params: {
              highlightId: this.currentHLId,
            },
          })
          .then((res) => {
            axios
              .get(axios.defaults.baseURL + "findUserById", {
                params: {
                  id: res.data.data.userId,
                },
              })
              .then((res) => {
                this.currentHLTextName = res.data.data.username;
              });
            this.currentHLTextTime = res.data.data.createTime;
          });
        this.hlComments = this.getCommentsByHLid(id);
      })
      .on(Highlighter.event.CREATE, ({ sources }) => {
        if (sessionStorage.getItem("init") != 1) {
          log("create -", this.authority + sources);
          sources = sources.map((hs) => ({ hs }));
          store.save(this.authority, sources);
        }
      })
      .on(Highlighter.event.REMOVE, ({ ids }) => {
        if (sessionStorage.getItem("init") != 1) {
        }
      })
      .on(Highlighter.event.HOVER, ({ id }) => {
        highlighter.addClass("highlight-wrap-hover", id);
      })
      .on(highlighter.event.HOVER_OUT, ({ id }) => {
        highlighter.removeClass("highlight-wrap-hover", id);
      });

    let hoveredTipId;
    document.addEventListener("mouseover", (e) => {
      const $ele = e.target;
      // toggle highlight hover state
      if (
        $ele.classList.contains("my-remove-tip") &&
        hoveredTipId !== $ele.dataset.id
      ) {
        hoveredTipId = $ele.dataset.id;
        highlighter.removeClass("highlight-wrap-hover");
        highlighter.addClass("highlight-wrap-hover", hoveredTipId);
      } else if (
        !$ele.classList.contains("my-remove-tip") &&
        !$ele.classList.contains("highlight-mengshou-wrap")
      ) {
        highlighter.removeClass("highlight-wrap-hover", hoveredTipId);
        hoveredTipId = null;
      }
    });

    //自定义一个浏览器右键菜单，单击右键是显示它
    //oncontextmenu上下文菜单事件，右键菜单
    document.documentElement.oncontextmenu = function (e) {
      e = e || window.event;
      var mx = e.clientX;
      var my = e.clientY;
      var articleDiv = document.getElementById("articleDiv");
      var scro = document.getElementById("scro");
      if (
        mx > scro.offsetLeft &&
        mx < scro.offsetLeft + scro.offsetWidth &&
        my > scro.offsetTop &&
        my < scro.offsetTop + scro.offsetHeight
      ) {
        var rm = document.getElementById("js-highlight");
        var rmWidth = parseInt(rm.style.width);
        var pageWidth = document.documentElement.clientWidth;
        if (mx + rmWidth < pageWidth) {
          rm.style.left = e.pageX + "px";
          rm.style.top = e.pageY + "px";
          rm.style.position = "absolute";
        } else {
          rm.style.left = e.pageX + "px";
          rm.style.top = e.pageY + "px";
        }
        rm.style.display = "block";

        return false; //阻止默认的右键菜单显示
      } else document.getElementById("js-highlight").style.display = "none";
    };

    //不需要积隐藏右键菜单
    document.documentElement.onclick = function (e) {
      document.getElementById("js-highlight").style.display = "none";
    };
  },

  methods: {
    //查看小组高亮
    searchGroupHighlight(groupId) {
      this.groupId = groupId;
      sessionStorage.setItem("teacherGro", groupId);
    },
    //查看小组某个成员
    searchPerson(val) {
      this.stuId = val;
      sessionStorage.setItem("stuId", this.stuId);
    },
    //按照权限查看高亮
    searchHighlight(val) {
      sessionStorage.setItem("qx", val);
      this.hlComments = null;
      this.replyment = null;
      this.currentHLText = null;
      this.currentHLTextName = null;
      this.currentHLTextTime = null;
      var articleId = sessionStorage.getItem("readingtaskId");
      //权限是学生个人
      if (val == 0 && this.stuId != 0) {
        axios
          .get(
            axios.defaults.baseURL + "articleTask/showHighlightInfoListByUser",
            {
              params: {
                articleId: articleId,
                userId: this.stuId,
              },
            }
          )
          .then((response) => {
            sessionStorage.setItem("init", 1);
            log(this.init);
            const storeInfos = response.data.data;
            highlighter.removeAll();
            //画出所有高亮内容
            storeInfos.forEach(({ hs }) =>
              highlighter.fromStore(
                hs.startMeta,
                hs.endMeta,
                hs.text,
                hs.id,
                hs.extra
              )
            );
            sessionStorage.setItem("init", 0);
          })
          .catch(function (error) {
            alert(error);
            return [];
          });
        sessionStorage.setItem("teacherAuth", 0);
      }
      //权限是小组
      else if (val == 1 && this.groupId != 0) {
        axios
          .get(
            axios.defaults.baseURL + "articleTask/showGroupHighlightInfoList",
            {
              params: {
                articleId: articleId,
                groupId: this.groupId,
              },
            }
          )
          .then((response) => {
            sessionStorage.setItem("init", 1);
            log(this.init);
            const storeInfos = response.data.data;
            highlighter.removeAll(); //画出所有高亮内容
            storeInfos.forEach(({ hs }) =>
              highlighter.fromStore(
                hs.startMeta,
                hs.endMeta,
                hs.text,
                hs.id,
                hs.extra
              )
            );
            sessionStorage.setItem("init", 0);
          })
          .catch(function (error) {
            alert(error);
            return [];
          });
        sessionStorage.setItem("teacherAuth", 1);
      }
      //权限是公开可见
      else if (val == 2) {
        axios
          .get(
            axios.defaults.baseURL + "articleTask/showPublicHighlightInfoList",
            {
              params: {
                articleId: articleId,
              },
            }
          )
          .then((response) => {
            sessionStorage.setItem("init", 1);
            log(this.init);
            const storeInfos = response.data.data;
            highlighter.removeAll();
            //画出所有高亮内容
            storeInfos.forEach(({ hs }) =>
              highlighter.fromStore(
                hs.startMeta,
                hs.endMeta,
                hs.text,
                hs.id,
                hs.extra
              )
            );
            sessionStorage.setItem("init", 0);
          })
          .catch(function (error) {
            alert(error);
            return [];
          });
        sessionStorage.setItem("teacherAuth", 2);
      }
      //小组内个人高亮
      else if (val == 3 && this.groupId != 0 && this.stuId != 0) {
        axios
          .post(
            axios.defaults.baseURL + "articleTask/showPersonalHighlightInGroup",
            {
              groupId: this.groupId,
              userId: this.stuId,
            }
          )
          .then((response) => {
            sessionStorage.setItem("init", 1);
            log(this.init);
            const storeInfos = response.data.data;
            highlighter.removeAll();
            //画出所有高亮内容
            storeInfos.forEach(({ hs }) =>
              highlighter.fromStore(
                hs.startMeta,
                hs.endMeta,
                hs.text,
                hs.id,
                hs.extra
              )
            );
            sessionStorage.setItem("init", 0);
          })
          .catch(function (error) {
            alert(error);
            return [];
          });
        sessionStorage.setItem("teacherAuth", 1);
      } else {
        this.$message.warning("权限错误");
      }
    },

    //列出小组成员
    selectGroupPerson(val) {
      this.currentHLText = "";
      this.currentHLTextName = "";
      this.currentHLTextTime = "";
      this.delHLbtnDis = true;
      this.hlComments = []; //所选择高亮的所有评论
      this.replyment = []; //评论的回复
      axios
        .post(axios.defaults.baseURL + "listAllMember", {
          groupId: val,
        })
        .then((response) => {
          this.itemArrayPerson = response.data.data;
        });
    },

    //子组件向父组件传数据
    father(data) {
      this.hlComments = data;
    },
    fatherhuifu(data) {
      this.replyment = data;
    },
    //获取高亮部分偏移位置
    getPosition($node) {
      let offset = {
        top: 0,
        left: 0,
      };
      while ($node) {
        offset.top += $node.offsetTop;
        offset.left += $node.offsetLeft;
        $node = $node.offsetParent;
      }
      return offset;
    },

    /**
     * avoid re-highlighting the existing selection
     */
    getIds(highlighter, selected) {
      if (!selected || !selected.$node || !selected.$node.parentNode) {
        return [];
      }
      return [
        highlighter.getIdByDom(selected.$node.parentNode),
        highlighter.getExtraIdByDom(selected.$node.parentNode),
      ].filter((i) => i);
    },

    getIntersection(arrA, arrB) {
      const record = {};
      const intersection = [];
      arrA.forEach((i) => (record[i] = true));
      arrB.forEach(
        (i) => record[i] && intersection.push(i) && (record[i] = false)
      );
      return intersection;
    },

    highlightText() {
      const selection = window.getSelection();
      if (selection.isCollapsed) {
        return;
      }
      highlighter.fromRange(selection.getRangeAt(0));
      window.getSelection().removeAllRanges();
    },

    delHL() {
      const id = this.currentHLId; // stored highlight id
      log("*click remove-tip*", id);
      var userId = localStorage.getItem("userId");
      axios
        .get(axios.defaults.baseURL + "articleTask/deleteHighlight", {
          params: {
            userId: userId,
            highlightId: id,
          },
        })
        .then((ret) => {
          let code = ret.data.status;
          if (code == 1) {
            this.$message.success("删除成功");
            highlighter.remove(id);
            this.delHLbtnDis = true;
            this.currentHLId = "";
            this.currentHLText = "";
            this.currentHLTextName = "";
            this.currentHLTextName = "";
            this.hlComments = [];
            this.replyment = [];
          } else {
            this.$message.error("删除失败");
          }
        });
    },

    //通过高亮ID从数据库获取所有的评论
    getCommentsByHLid(hlId) {
      this.currentHLId = hlId;
      var QuanXian = sessionStorage.getItem("qx");
      if (QuanXian == 0) {
        axios
          .post(axios.defaults.baseURL + "listPingLunByHId", {
            highLightId: hlId,
            authority: 0,
            userId: this.stuId,
          })
          .then((response) => {
            this.hlComments = response.data.data;
          });
      } else if (QuanXian == 1) {
        axios
          .post(axios.defaults.baseURL + "listPingLunByHId", {
            highLightId: hlId,
            authority: 1,
            userId: 0,
            groupId: this.groupId,
          })
          .then((response) => {
            //console.log(response.data.data);
            this.hlComments = response.data.data;
          });
      } else if (QuanXian == 2) {
        axios
          .post(axios.defaults.baseURL + "listPingLunByHId", {
            highLightId: hlId,
            authority: 2,
          })
          .then((response) => {
            //console.log(response.data.data);
            this.hlComments = response.data.data;
          });
      } else if (QuanXian == 3) {
        axios
          .post(axios.defaults.baseURL + "listPingLunByHId", {
            highLightId: hlId,
            authority: 1,
            userId: this.stuId,
            groupId: this.groupId,
          })
          .then((response) => {
            //console.log(response.data.data);
            this.hlComments = response.data.data;
          });
      }
    },
  },

  beforeDestroy() {
    document.documentElement.onclick = function (e) {};
    document.documentElement.oncontextmenu = function (e) {};
    sessionStorage.removeItem("init");
  },
  components: {
    CommentContent1,
    TeaChat
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}

.liBackground {
  background: #409eff;
  color: #fff;
}
.rightBox {
  background: #fff;
  padding-bottom: 2em;
  padding-top: 5px;
}
.btnHighlight {
  width: 80px;
  z-index: 1000;
}
.selfContainer img {
  display: block !important;
  width: 50%;
  margin: 0 auto !important;
}
</style>
