<template>
    <div class="tea-chat">
        <div  @click="togglePanel" style="margin-top:10px">
          <el-button>打开/重置聊天面板</el-button>
        </div>
        <div class="chat-container animated fadeIn" v-show="show" ref="panel" @mousedown="mousedown" @touchstart.stop="touchStart">
          <div class="close" @click="show = false"><i class="el-icon-close"></i></div>
          <lemon-imui
              :user="user"
              ref="IMUI"
              :contextmenu="contextmenu"
              :contact-contextmenu="contactContextmenu"
              :theme="theme"
              :hide-menu="hideMenu"
              :hide-menu-avatar="hideMenuAvatar"
              :hide-message-name="hideMessageName"
              :hide-message-time="hideMessageTime"
              @change-menu="handleChangeMenu"
              @change-contact="handleChangeContact"
              @pull-messages="handlePullMessages"
              @message-click="handleMessageClick"
              @send="handleSend"
              width="900px"
          />
        </div>
        <el-dialog title="发布限时任务" :visible.sync="dialogFormVisible">
        <el-form :model="form">
            <el-form-item label="任务标题" :label-width="formLabelWidth">
                <el-input style="width:80%" v-model="form.title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="任务简介" :label-width="formLabelWidth">
                <el-input style="width:80%" v-model="form.introduction" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="选择小组" :label-width="formLabelWidth">
                <el-select v-model="form.group" multiple collapse-tags placeholder="请选择">
                    <el-option
                    v-for="item in groupList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
                <el-button style="margin-left:10px" @click="selectAll">全选/重置</el-button>
            </el-form-item>
            <el-form-item label="截止时间" prop="date" :label-width="formLabelWidth">
                <el-date-picker value-format='yyyy-MM-dd' type="date" placeholder="选择日期" v-model="form.date1" style="width: 30%;"></el-date-picker>
                <el-time-picker value-format='HH:mm:ss' type="time" placeholder="选择时间" v-model="form.date2" style="width: 30%;margin-left:10px"></el-time-picker>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="submit()">确 定</el-button>
        </div>
        </el-dialog>
        <el-dialog title="我发布的任务" :visible.sync="lookTask">
        <div class="task-container">
          <el-form>
            <el-form-item label="任务标题：" :label-width="formLabelWidth" style="width:300px">
                <el-select v-model="curTask" :placeholder="taskList[0]?taskList[0].title:'暂无任务'" @change="getResult('change-task')">
                  <el-option
                    v-for="(item,index) in taskList"
                    :key="item.id"
                    :label="item.title"
                    :id="item.id"
                    :value="index">
                  </el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item label="小组号：" :label-width="formLabelWidth">
              <el-select v-model="curGroup" placeholder="全部小组统计"  @change="getResult">
                <el-option
                  v-for="(item,index) in taskList[curTask]?taskList[curTask].groupId.split(';'):['暂无小组']"
                  :key="index"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="开始时间：" :label-width="formLabelWidth" style="margin-bottom:0px">{{taskList[curTask]?taskList[curTask].createTime:'暂无数据'}}</el-form-item>    
            <el-form-item label="截止时间：" :label-width="formLabelWidth" style="margin-bottom:0px">{{taskList[curTask]?taskList[curTask].finishTime:'暂无数据'}}</el-form-item>
            <el-form-item label="讨论活跃预警：" :label-width="formLabelWidth" style="margin-bottom:0px;font-weight:bold;">
              <!-- <span style="color:red;font-size:20px;font-weight:bold;" v-for="(item,index) in minUserIds"
                  :key="index"
                  :label="item"
                  :value="item">
                第{{item}}组</span> -->
                <span style="color:red;font-size:20px;font-weight:bold;">
                {{talk_resultList[0]?talk_resultList[0].group_id + "小组":'暂无数据'}}</span>
            </el-form-item>    
          </el-form>
          <div class="task-chart"></div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="lookTask = false">关闭</el-button>
        </div>
        </el-dialog>
        <!-- 图片预览 -->
        <div @click="showImg=false" style="position:fixed;width:100vw;top:0;left:0;height:100vh;background:#00000080;" v-if="showImg">
          <el-image :src="imgUrl" style="width:350px;position:absolute;left:50%;top:50%;transform:translatex(-50%) translateY(-50%)" fit="cover"></el-image>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import QQIMUI from "../plugins/qq";
import EmojiData from "../plugins/database/emoji";
import lemonMessageNotice from "../plugins/lemon-message-notice";
Vue.component(lemonMessageNotice.name, lemonMessageNotice);
Vue.component(QQIMUI.name, QQIMUI);

const getTime = () => {
  return new Date().getTime();
};
const generateRandId = () => {
  return Math.random()
    .toString(36)
    .substr(-8);
};
const generateRandWord = () => {
  return Math.random()
    .toString(36)
    .substr(2);
};
const chatUrl = 'http://47.99.140.190:8001';
export default {
  data() {
    return {
      ws:null,
      show:false,
      showImg:false,
      imgUrl:'',
      theme: "default",
      // 小组成员
      member:[],
      //所有小组
      group:[],
      groupList:[],
      // 右键点击联系人功能面板
      contactContextmenu: [
        {
          text: "删除该聊天",
          click: (e, instance, hide) => {
            const { IMUI, contact } = instance;
            IMUI.updateContact({
              id: contact.id,
              lastContent: null,
            });
            if (IMUI.currentContactId == contact.id) IMUI.changeContact(null);
            hide();
          },
        },
        {
          text: "设置备注和标签",
        },
        {
          text: "投诉",
        },
        {
          icon: "lemon-icon-message",
          render: (h, instance, hide) => {
            return (
              <div style="display:flex;justify-content:space-between;align-items:center;width:130px">
                <span>加入黑名单</span>
                <span>
                  <input type="checkbox" id="switch" />
                  <label id="switch-label" for="switch">
                    Toggle
                  </label>
                </span>
              </div>
            );
          },
        },
        {
          click(e, instance, hide) {
            const { IMUI, contact } = instance;
            IMUI.removeContact(contact.id);
            if (IMUI.currentContactId == contact.id) IMUI.changeContact(null);
            hide();
          },
          color: "red",
          text: "删除好友",
        },
      ],
      // 右键点击消息面板
      contextmenu: [
        {
          click: (e, instance, hide) => {
            const { IMUI, message } = instance;
            const data = {
              id: generateRandId(),
              type: "event",
              //使用 jsx 时 click必须使用箭头函数（使上下文停留在vue内）
              content: (
                <div>
                  <span>
                    你撤回了一条消息{" "}
                    <span
                      v-show={message.type == "text"}
                      style="color:#333;cursor:pointer"
                      content={message.content}
                      on-click={e => {
                        IMUI.setEditorValue(e.target.getAttribute("content"));
                      }}
                    >
                      重新编辑
                    </span>
                  </span>
                </div>
              ),

              toContactId: message.toContactId,
              sendTime: getTime(),
            };
            IMUI.removeMessage(message.id);
            IMUI.appendMessage(data, true);
            hide();
          },
          visible: instance => {
            return instance.message.fromUser.id == this.user.id;
          },
          text: "撤回消息",
        },
        {
          visible: instance => {
            return instance.message.fromUser.id != this.user.id;
          },
          text: "举报",
        },
        {
          text: "转发",
        },
        {
          visible: instance => {
            return instance.message.type == "text";
          },
          text: "复制文字",
        },
        {
          visible: instance => {
            return instance.message.type == "image";
          },
          text: "下载图片",
        },
        {
          visible: instance => {
            return instance.message.type == "file";
          },
          text: "下载文件",
        },
        {
          click: (e, instance, hide) => {
            const { IMUI, message } = instance;
            IMUI.removeMessage(message.id);
            hide();
          },
          icon: "lemon-icon-folder",
          color: "red",
          text: "删除",
        },
      ],
      hideMenuAvatar: false,
      hideMenu: false,
      hideMessageName: false,
      hideMessageTime: true,
      //个人信息
      user: {
        id: localStorage.getItem('chatId'),
        displayName: localStorage.getItem('username'),
        avatar: "",
      },
      dialogFormVisible: false,
      formLabelWidth: '120px',
      //表单验证
      form: {
          title: '',
          introduction: '',
          date1: '',
          date2: '',
          group:null,
      },
      //我的任务
      lookTask:false,
      curTask:0,
      curGroup:'',
      taskList:[],      
      talk_resultList:[]
    };
  },
  watch:{
    //等待父组件的异步小组成员数据传下来之后再初始化联系人
    member(){
        //...调用接口初始化联系人以及群组数据
        //bug 在某些浏览器里按顺序push列表 会出现列表数据错乱
        const { IMUI } = this.$refs;
        const contactList = [];

        for(let item of this.group){
          contactList.push({
            id:item,
            displayName:`第${item}组`,
            avatar: "http://upload.qqbodys.com/img/weixin/20170804/ji5qxg1am5ztm.jpg",
            index:`[1]群组`,
            unread:0,
            lastSendTime: 0,
            lastContent: " ",
          })
        }

        for(let item of this.member){
          if(item.id == localStorage.getItem('userId')) continue;
          contactList.push({
            id:item.user_id,
            displayName:item.username,
            avatar: "http://upload.qqbodys.com/img/weixin/20170804/ji5qxg1am5ztm.jpg",
            index:`[2]联系人`,
            unread:0,
            lastSendTime: 0,
            lastContent: " ",
          });
        };


        // //初始化聊天联系人板块
        IMUI.initContacts(contactList);

        setTimeout(() => {
          IMUI.changeContact(contactList[0].id);
          IMUI.messageViewToBottom();
        }, 500);
    }
  },
  mounted() {
    console.log('courseId',sessionStorage.getItem('courseId'))
    // 初始化收到的通知
    console.log("receivedNotice",localStorage.getItem('receivedNotice'))
    if(!localStorage.getItem('receivedNotice')){
      localStorage.setItem('receivedNotice',JSON.stringify([-1]))
    }
    //获取全部小组及成员数据
    axios.get(chatUrl+"/memberList?courseId="+sessionStorage.getItem('courseId'))
      .then((res) => {
        this.group = res.data.memberList.groupList;
        this.groupList = res.data.memberList.groupList.map((item,index)=>{
          return {
            label:item,
            value:item
          }
        });
        this.member = res.data.memberList.userList;
      });

    const { IMUI } = this.$refs;
    // 输入框上方工具栏
    IMUI.initEditorTools([
      {
        name: "emoji",
      },
      {
        name: "uploadImage",
      },
      {
        name: "",
        isRight: true,
        title: "我发布的任务",
        click: () => {
            this.getTask();
        },
        render: () => {
          return <el-button size="mini" icon="el-icon-s-order" circle></el-button>;
        },
      },
      {
        name: "",
        isRight: true,
        title: "发布限时任务",
        click: () => {
            this.sendTask();
        },
        render: () => {
          return <el-button size="mini" icon="el-icon-edit" circle></el-button>;
        },
      },
    ]);
    //初始化表情
    IMUI.initEmoji(EmojiData);
    //初始化限时任务左侧消息预览文字
    IMUI.setLastContentRender("notice", message => {
        return `[限时任务]`;
    });
    //建立websocket连接
    this.ws = new WebSocket(`ws://47.99.140.190:8000?userId=${localStorage.getItem('chatId')}`);
    this.ws.onopen = (e) => {
      console.log('打开连接')
    };

    this.ws.onclose = (e) => {
      console.log('断开连接',e)
    }

    //监听消息
    this.ws.onmessage = (e) => {
      let info = JSON.parse(e.data);
      console.log('接收消息',info);
      if((info.fromUserId == localStorage.getItem('chatId'))) return;
      if(info.msgType == 'notice'){
        const data = {
          id: info.id,
          status: 'succeed',
          type: 'notice',
          sendTime: info.sendTime,
          content: JSON.parse(info.content),
          toContactId: info.toContactId,
          fromUser:{
            //如果 id == this.user.id消息会显示在右侧，否则在左侧
            id:info.fromUserId,
            displayName:info.fromUsername,
            avatar:info.fromUserAvatar,
          }
        };
        console.log(data)
        IMUI.appendMessage(data);
        IMUI.messageViewToBottom();
        return;
      }

      const data = {
        id: generateRandId(),
        status: 'succeed',
        type: info.msgType,
        sendTime: new Date().getTime(),
        content: info.content,
        toContactId: info.type=='group'?info.toContactId:info.fromUserId,
        fromUser:{
          //如果 id == this.user.id消息会显示在右侧，否则在左侧
          id:info.fromUserId,
          displayName:info.fromUsername,
          avatar:info.fromUserAvatar,
        }
      };
      console.log("消息内容：" + data.toString());
      IMUI.appendMessage(data);
      IMUI.messageViewToBottom();
    }
  },
  methods: {
    handleMessageClick(e, key, message) {
        let { IMUI } = this.$refs;
        //预览图片
        if(message.type=='image'){
          this.showImg = true;
          this.imgUrl = message.content;
        }
    },
    removeMessage() {
      const { IMUI } = this.$refs;
      const messages = IMUI.getCurrentMessages();
      const id = messages[messages.length - 1].id;
      if (messages.length > 0) {
        IMUI.removeMessage(id);
      }
    },
    updateMessage() {
      const { IMUI } = this.$refs;
      const messages = IMUI.getCurrentMessages();
      const message = messages[messages.length - 1];
      if (messages.length > 0) {
        const update = {
          id: message.id,
          status: "succeed",
          type: "file",
          fileName: "被修改成文件了.txt",
          fileSize: "4200000",
        };
        if (message.type == "event") {
          update.fromUser = this.user;
        }
        IMUI.updateMessage(update);
        IMUI.messageViewToBottom();
      }
    },
    //发送限时任务 群通知
    appendNoticeMessage(groupId) {
        const { IMUI } = this.$refs;
        axios({
          url:chatUrl+'/task',
          method:'POST',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data:{
            creatorId:localStorage.getItem('chatId'),
            groupId:groupId,
            title:this.form.title,
            introduction:this.form.introduction,
            finishTime:this.form.date1 + ' ' +this.form.date2
          },
        }).then((res)=>{
          for(let item of groupId.split(';')){
            this.ws.send(JSON.stringify({
              fromUserId:localStorage.getItem('chatId'),
              fromUsername:localStorage.getItem('username'),
              fromUserAvatar:localStorage.getItem('userAvatar'),
              toContactId:item,
              content:JSON.stringify({
                title:this.form.title,
                introduction:this.form.introduction,
                date1:this.form.date1,
                date2:this.form.date2,
                group:item,
                taskId:res.data.taskId,
              }),
              type:'group',
              msgType:'notice',
            }));
            this.$message.success('发布成功！');
            const message = {
              id: generateRandId(),
              status: "succeed",
              type: "notice",
              sendTime: getTime(),
              content: {
                  title:this.form.title,
                  introduction:this.form.introduction,
                  date1:this.form.date1,
                  date2:this.form.date2,
              },
              toContactId: item,
              fromUser: this.user,
          };
          IMUI.appendMessage(message, true);
          };
          this.form = {};
        })
    },
    appendMessage() {
      // const { IMUI } = this.$refs;
      // const contact = IMUI.currentContact;
      // const message = generateMessage("contact-3");
      // message.fromUser = {
      //   ...message.fromUser,
      //   ...this.user,
      // };
      // IMUI.appendMessage(message, true);
    },
    updateContact() {
      this.$refs.IMUI.updateContact({
        id: "contact-3",
        unread: 10,
        displayName: generateRandWord(),
        lastSendTime: getTime(),
        lastContent: "修改昵称为随机字母",
      });
    },
    handleChangeContact(contact, instance) {
      console.log("Event:change-contact");
      instance.updateContact({
        id: contact.id,
        unread: 0,
      });
      instance.closeDrawer();
    },
    //本人发送消息
    handleSend(message, next, file) {
      console.log('message',message)
      if(message.type == 'image'){
         let fd = new FormData();
        fd.append('file',file,file.fileName)
        axios.post(chatUrl + '/upload',fd,{
          headers: { "content-type": `multipart/form-data;boundary='+${new Date().getTime()}` }
        }).then(res=>{
            this.ws.send(JSON.stringify({
            fromUserId:localStorage.getItem('chatId'),
            fromUsername:localStorage.getItem('username'),
            fromUserAvatar:localStorage.getItem('userAvatar'),
            toContactId:message.toContactId,
            content:res.data.imgUrl,
            type:parseInt(message.toContactId)>=100?'user':'group',
            msgType:'image'
          }));
          next();
        });
      }else{
        this.ws.send(JSON.stringify({
          fromUserId:localStorage.getItem('chatId'),
          fromUsername:localStorage.getItem('username'),
          fromUserAvatar:localStorage.getItem('userAvatar'),
          toContactId:message.toContactId,
          content:message.content,
          type:parseInt(message.toContactId)>=100?'user':'group',
          msgType:'text'
        }));
        next();
      }
    },
    //从后端请求消息数据
    handlePullMessages(contact, next, instance) {
      document.querySelector('.lemon-messages__loadend').style.display = 'none';
      axios.get(`${chatUrl}/historyMsg?userId=${localStorage.getItem('chatId')}&groupId=all`)
      .then(res=>{
        let message_history = res.data.msgList.reduce((prev,item)=>{
          //逻辑处理 显示私聊和群聊 的信息 并进行分割
          if((item.toContactId==contact.id&&localStorage.getItem('chatId')==item.fromUserId)||
             (item.fromUserId==contact.id&&localStorage.getItem('chatId')==item.toContactId)||
             (item.toContactId==contact.id&&parseInt(item.toContactId)<=100)){
            prev.push({
              id:item.id,
              status: 'succeed',
              type: item.type,
              sendTime:item.sendTime,
              content:(item.type=='text'||item.type=='image')?item.content:JSON.parse(item.content),
              toContactId:item.toContactId,
              fromUser:{
                id:item.fromUserId,
                displayName:item.fromUsername,
                avatar:item.fromUserAvatar
              }
            });
          }
          
          return prev; 
      },[]);
      document.querySelector('.lemon-messages__loadend').style.display = 'block';
      next(message_history,true);  
      const { IMUI } = this.$refs;
      IMUI.messageViewToBottom();
      });
    },
    handleChangeMenu() {
      console.log("Event:change-menu");
    },
    openCustomContainer() {},
    togglePanel(){
      if(!localStorage.getItem('chatId')){
        this.$message.error('当前登录失效，请尝试重新登录或刷新页面后再进入聊天室！');
        return;
      }
      if(this.member.length==0){
        this.$message.error('当前课程暂无小组和成员！');
        return;
      }
      this.show = true;
      this.selectElement = this.$refs.panel;
      var panel = this.selectElement;
      panel.style.top = '50%';
      panel.style.left = '50%';
      const { IMUI } = this.$refs;
      IMUI.messageViewToBottom();
    },
    //发布限时任务
    sendTask(){
        this.dialogFormVisible = true;
    },
    //查看个人发布的任务
    getTask(){
      this.lookTask = true;
      axios.get(chatUrl+'/task?userId='+localStorage.getItem('chatId'))
      .then(res=>{
        console.log(res);
        this.taskList = res.data.taskList;
        this.getResult();
      })
    },
    //得到任务活跃结果
    getResult(type){
      if(type=='change-task') this.curGroup='';
      var chart = echarts.init(document.querySelector('.task-chart'));
      if(!this.taskList[this.curTask]){
        var option = {
            title: {
                text: '讨论活跃度',
                subtext: 'Discussion Data',
                left: 'center',
                top:20,
            },
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                left: 'left'
            },
            series: [{
                type: 'pie',
                radius: '60%',
                center:['50%','60%'],
                data: [],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }]
        };
        chart.setOption(option);
        return;
      };

      axios.get(chatUrl+'/talk_result?taskId='+this.taskList[this.curTask].id + '&groupId='+this.curGroup)
      .then(res=>{
        console.log(res);
        this.talk_resultList = res.data.resultList;
        let data = res.data.resultList.map(item=>{
          return {
            name:item.group_id,
            value:item.count
          }
        })
        var option = {
            title: {
                text: '讨论活跃度',
                subtext: 'Discussion Data',
                left: 'center',
                top:20,
            },
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                left: 'left'
            },
            series: [{
                type: 'pie',
                radius: '60%',
                center:['50%','60%'],
                data: data,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }]
        };
        chart.setOption(option)
      })
    },
    // 全选小组
    selectAll(){
      if(this.form.group.length==this.group.length){
        this.form.group = [];
      }else{
        this.form.group = this.group;
      }
    },
    //提交限时任务
    submit() {
        console.log(this.form.group.join(';'))
        if(!this.form.title||!this.form.introduction||!this.form.group.length||!this.form.date1||!this.form.date2){
            this.$message.error('请完善所有信息！');
            return;
        }
        if(new Date(this.form.date1 + ' ' +this.form.date2) <= Date.now()){
          this.$message.error('截止时间不能小于当前时间！');
          return;
        }
        this.dialogFormVisible = false;
        this.appendNoticeMessage(this.form.group.join(';'));
        
    },
    //脱拽
    mousedown(event) {
        this.selectElement = this.$refs.panel;
        var div1 = this.selectElement
        this.selectElement.style.cursor = 'move'
        this.isDowm = true
        var distanceX = event.clientX - this.selectElement.offsetLeft
        var distanceY = event.clientY - this.selectElement.offsetTop
        document.onmousemove = function (ev) {
          var oevent = ev || event
          div1.style.left = oevent.clientX - distanceX + 'px'
          div1.style.top = oevent.clientY - distanceY + 'px'
        }
        document.onmouseup = function () {
          document.onmousemove = null
          document.onmouseup = null
          div1.style.cursor = 'default'
        }
    },
    
    touchStart(event) {
        this.selectElement = this.$refs.panel;
        var div1 = this.selectElement
        this.selectElement.style.cursor = 'move'
        this.isDowm = true
        var touchDistanceX = event.changedTouches[0].clientX - this.selectElement.offsetLeft
        var touchDistanceY = event.changedTouches[0].clientY - this.selectElement.offsetTop
        this.$refs.panel.addEventListener('touchmove', function (ev) {
          var oevent = ev || event
          div1.style.left = oevent.changedTouches[0].clientX - touchDistanceX + 'px'
          div1.style.top = oevent.changedTouches[0].clientY - touchDistanceY + 'px'
          //拖动时穿透
          ev.preventDefault()
        }, { passive: false })
        this.$refs.panel.addEventListener('touchend', function () {
          div1.style.cursor = 'default'
        })
        //拖动时穿透
        ev.preventDefault()
    }
  },
};
</script>

<style lang="less" scoped>
.chat-container{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 900px;
  box-shadow: 0 0 6px 0px #424242;
}
.close{
  position: absolute;
  top: 8px;
  right: 8px;
  color: rgb(141, 141, 141);
  z-index: 999;
  cursor: pointer;
  font-size: 20px;
}

.task-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.task-chart{
  height: 300px;
  width: 400px;
  margin-left: 20px;
}
/deep/ .el-dialog{
  width: 60vw;
}
</style>