<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/teachercourse' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>小组成员</el-breadcrumb-item>    
    </el-breadcrumb>
    <el-card>
      <el-table :data="array">
        <el-table-column label="学号" prop="stuId"></el-table-column>
        <el-table-column label="姓名" prop="username"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      array: [],
    };
  },
  mounted() {
    //列出课程所有小组
    var groupId = sessionStorage.getItem("groupId");
    axios
      .post(axios.defaults.baseURL + "listAllMember", {
        groupId: groupId,
      })
      .then((res) => {
        console.log(res.data.data);
        this.array = res.data.data;
      });
  },
};
</script>
