<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/allcourse' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>选择课程</el-breadcrumb-item>
      <el-breadcrumb-item>全部课程</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 所有课程显示区域 -->
    <el-card>
      <el-table
        ref="multipleTable"
        :data="
          tables.slice((currentPage - 1) * pagesize, currentPage * pagesize)
        "
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :row-key="rowKey"
        :row-style="setRowStyle"
      >
        <el-table-column prop="courseId" label="课程号" sortable width="120">
        </el-table-column>
        <el-table-column prop="courseName" label="课程名" sortable width="200">
        </el-table-column>
        <el-table-column
          prop="startTime"
          label="开始时间"
          sortable
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="endTime"
          label="结束时间"
          sortable
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column align="right">
          <template slot="header" slot-scope="scope">
            <el-input v-model="search" size="mini" placeholder="请输入课程名" />
          </template>
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row);logSelectCourse(scope.row.courseName)"
              >选择</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        small
        :page-size="pagesize"
        :page-sizes="[2, 5, 8, 10]"
        :pager-count="5"
        layout="prev, pager, next"
        :total="this.tableData.length"
        style="text-align: center; margin-top: 1%"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page="currentPage"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      search: "",
      currentPage: 1,
      pagesize: 5,
      nowDate:"",
    };
  },
  mounted() {
    this.currentTime();
    this.setColorInLocalStorage();

    //列出所有课程
    axios.get(axios.defaults.baseURL + "listAllCourse").then((response) => {
      // this.tableData = response.data.data;
      this.tableData = response.data.data.filter(course => new Date(course.endTime) > new Date());
    });
  },
  computed: {
    tables: {
      get() {
        const search = this.search;
        if (search) {
          return this.tableData.filter((data) => {
            return Object.keys(data).some((key) => {
              return String(data[key]).toLowerCase().indexOf(search) > -1;
            });
          });
        }
        return this.tableData;
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  methods: {
    currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位,则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位,则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位,则前面补零
      this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second}`;
    },
    async setColorInLocalStorage() {
      try {
        const stuId = localStorage.getItem('chatId');
        if (!stuId) {
          console.error('chatId not found in localStorage.');
          return;
        }

        console.log('Sending request to get color for stuId:', stuId);
        console.log('type for stuId:', typeof(stuId));
        // 发起请求，确保API的URL和参数设置正确
        const response = await axios.get(axios.defaults.baseURL +'color',{
          params: { stuId: stuId },
        });

        console.log('API response received:', response.data); // 打印API响应

        if (response.data && response.data.status === 1 && response.data.data && response.data.data.color) {
          const color = response.data.data.color;
          localStorage.setItem('color', color);
          console.log('Color has been stored in localStorage:', color);
        } else {
          console.error('Invalid response data:', response.data);
        }
      } catch (error) {
        console.error('Error fetching color:', error);
        // 这里可以处理请求失败的情况，例如重试或提示用户
      }
    },
    logSelectCourse(val){
      this.saveLog("选课《"+val+"》",this.nowDate);
      var userId = localStorage.getItem("userId");
      var username = localStorage.getItem("username");
      var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username: username,
	        groupId: groupId,
          stuId: userId,
          log: "选课《"+val+"》",
          createTime: this.nowDate,
        })
        .then((res) => {});

    },
    handleEdit(index, row) {
      var userId = localStorage.getItem("userId");
      axios
        .post(axios.defaults.baseURL + "selectCourse", {
          userId: userId,
          courseId: row.courseId,
        })
        .then((response) => {
          if (response.data.status == 1) {
            this.$message.success("提交成功");
            this.$refs.multipleTable.clearSelection();
          } else this.$message.error("请不要重复选择");
        });
    },
    rowKey(rows) {
      return rows.courseId;
    },
    getPageData() {
      let start = (this.currentPage - 1) * this.pagesize;
      let end = start + this.pagesize;
      this.tables = this.tableData.slice(start, end);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPageData();
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.getPageData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    timecha(beginTime, endTime) {
      var dateBegin = new Date(beginTime);
      var dateEnd = new Date(endTime);
      var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      this.seconds = seconds;
      this.minutes = minutes;
      return leave1
    },
    setRowStyle(row) {
      // console.log(this.nowDate,row.row.endTime)
      let stylejson={}
      // console.log("时间差"+this.timecha(this.nowDate,row.row.endTime))
      if ((this.timecha(this.nowDate,row.row.endTime))<0) {
        // return 'color:red;'
        // stylejson.color='red'
        // stylejson.display='none'
        return stylejson
      }
    },
  },
};
</script>