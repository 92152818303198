<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/allcourse' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>所有任务</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- <div slot="header">
        <el-button type="text" @click="showreadingtask(0)">全部</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="text" @click="showreadingtask(1)"
          >未截止已完成</el-button
        >
        <el-button type="text" @click="showreadingtask(2)"
          >未截止未完成</el-button
        >
        <el-button type="text" @click="showreadingtask(3)"
          >已截止已完成</el-button
        >
        <el-button type="text" @click="showreadingtask(4)"
          >已截止未完成</el-button
        >
      </div> -->
      <el-table :data="tableData" style="width: 100%" :show-header="true" :row-style="setRowStyle">
        <!-- <el-table-column width="80">
          <el-progress
            type="circle"
            :percentage="25"
            :width="50"
            :height="50"
          ></el-progress>
        </el-table-column> -->
        <el-table-column width="160" label="任务名" >
          <template slot-scope="scope">
            <span >{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="explain" width="160"> </el-table-column> -->
        <el-table-column prop="endTime" label="结束时间"  width="300"></el-table-column>
        <!-- 结课倒计时 -->
        <!-- <el-table-column width="400">
          <template slot-scope="scope">
            <el-progress
              :percentage="0"
              :format="setItemText(scope.row.endTime)"
            ></el-progress>
          </template> 
        </el-table-column> -->
        <el-table-column>
          <template slot-scope="scope">
            <el-button
              type="text"
              size="mini"
              @click="handleEdit(scope.$index, scope.row,'开始阅读任务');"
              >开始阅读</el-button
            >
            <el-button
              type="text"
              size="mini"
              @click="getGroupData(scope.row);"
              >小组数据</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      tableData: [],
      authority: 0, //全部：0  未截止已完成：1   未截止未完成：2   已截止已完成：3    已截止未完成：4
      array: [],
      nowDate: "", // 当前日期
    };
  },

  mounted() {
  
    //请求后台课程号courseId的所有的阅读任务
    var courseId = sessionStorage.getItem("courseId");
    if (courseId == 0) {
      this.$message.warning("请先选择一门课程");
    } else {
      axios
        .post(axios.defaults.baseURL + "taskList", {
          courseId: courseId,
        })
        .then((Response) => {
          this.tableData = Response.data.data;
        });
    }
    this.currentTime();
  },
  methods: {
    currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
    },
    showreadingtask(authority) {
      //this.authority=authority;
      switch (authority) {
        case 0: //全部 0
        case 1: //未截止已完成：1
        case 2: //未截止未完成：2
        case 3: //已截止已完成：3
        case 4: //已截止未完成：4
      }
    },
    setItemText(row) {
      return () => {
        return this.timediff(this.nowDate,row);
      };
    },
    handleEdit(index, row,val) {
      sessionStorage.setItem("readingtaskId", row.id);
      var groupId = localStorage.getItem("groupId");
      if (groupId == 0) {
        this.$router.push("/readingtasknull");
      } else {
        this.$router.push("/readingtask");
      }
      this.saveLog(val+"《"+row.title+"》",this.nowDate);
      var userId = localStorage.getItem("userId");
      var username = localStorage.getItem("username");
      var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username: username,
	        groupId: groupId,
          stuId: userId,
          log: "开始阅读任务《"+row.title+"》",
          createTime: this.nowDate,
        })
        .then((res) => {
        });
    },
    getGroupData(row){
      sessionStorage.setItem("readingtaskId",row.id);
      this.$router.push("/groupdata")
    },
    //获取当前年月日
    currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      // this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
      this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second}`;
    },
    timediff(beginTime, endTime) {
      var dateBegin = new Date(beginTime);
      var dateEnd = new Date(endTime);
      var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      this.seconds = seconds;
      this.minutes = minutes;
      return (
        "任务截止还剩" +
        dayDiff +
        "天" +
        hours +
        "小时" +
        minutes +
        "分钟" +
        seconds +
        "秒"
      );
    },
    timecha(beginTime, endTime) {
      var dateBegin = new Date(beginTime);
      var dateEnd = new Date(endTime);
      var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      this.seconds = seconds;
      this.minutes = minutes;
      return leave1
    },
    setRowStyle(row) {
      let stylejson={}
      // console.log(this.timecha(row.row.startTime,row.row.endTime))
      if ((this.timecha(this.nowDate,row.row.endTime))<0) {
        // return 'color:red;'
        // stylejson.color='red'
        // stylejson.display='none'
        return stylejson
      }
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-progress-bar__outer {
    background-color: rgba(255, 251, 251, 0.459);
}
</style>