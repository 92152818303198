<template>
  <div class="login_container">
    <div class="login_box">
      <!-- 头像区 -->
      <div class="avatar_box">
        <img src="../assets/pic/logo.png" alt="" />
      </div>
      <!-- 登录表单区 -->
      <el-form
        ref="loginFormRef"
        :model="loginForm"
        :rules="loginFormRules"
        class="login_form"
      >
        <!-- 用户名 -->
        <el-form-item prop="username">
          <el-input
            v-model="loginForm.username"
            prefix-icon="iconfont icon-0-29"
            placeholder="请输入账号"
          >
          </el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            prefix-icon="iconfont icon-suo"
            placeholder="请输入密码"
            type="password"
          >
          </el-input>
        </el-form-item>
        <!-- 按钮区 -->
        <el-form-item prop="radio">
          <el-radio-group v-model="loginForm.radio">
            <el-radio :label="1" @change="changeRadio($event)">教师</el-radio>
            <el-radio :label="2" @change="changeRadio($event)">学生</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="login()">登录</el-button>
          <!-- <el-button type="info" @click="register">注册</el-button> -->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      shenfen: "",
      //这是登录表单的数据绑定对象
      loginForm: {
        username: "",
        password: "",
        radio: 3,
      },
      //这是表单的验证规则对象
      loginFormRules: {
        //验证用户名是否合法
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
          // {
          //   min: 10,
          //   max: 10,
          //   message: "长度为10个字符",
          //   trigger: "blur",
          // },
        ],
        //验证密码是否合法
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            //min: 6,
            //max: 15,
            //message: "长度在 6 到 15 个字符",
            //trigger: "blur",
          },
        ],
        radio: [
          {
            required: true,
            message: "请选择身份",
            trigger: "change",
          },
        ],
      },
      array: [],
    };
  },
  mounted(){
    this.currentTime();
  },
  methods: {
    currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位,则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位,则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位,则前面补零
      this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
    },

    changeRadio(val) {
      this.shenfen = val;
    },
    login() {
      this.$refs.loginFormRef.validate((valid) => {
        //如果用户名和密码验证通过,则发起请求
        if (!valid) return this.$message.error("登录失败");
        else {
          if (this.shenfen == 1) {
            axios
              .post(axios.defaults.baseURL + "loginTeacher", {
                username: this.loginForm.username,
                password: this.loginForm.password,
              })
              .then((res) => {
                if (res.data.status == 1) {
                  this.$message.success("登录成功");
                  localStorage.setItem("userId", res.data.data.teacherId);
                  localStorage.setItem("username", res.data.data.email);
                  localStorage.setItem("userAvatar", res.data.data.header_url);
                  localStorage.setItem("chatId", this.loginForm.username);
                  localStorage.setItem("groupId",0);
                  sessionStorage.setItem("courseId", 0);
                  sessionStorage.setItem("readingtaskId", 0);
                  this.saveLog("登录成功", this.nowDate);
                  axios
                  .post(axios.defaults.baseURL + "addAllLogs", {
                    username: res.data.data.email,
                    groupId: 0,
                    stuId: res.data.data.teacherId,
                    log: "登录成功",
                    createTime: this.nowDate,
                  })
                  .then((res) => {});
                  this.$router.push("/teacherhome");
                } else {
                  this.$message.error(res.data.message);
                }
              });
          } else if (this.shenfen == 2) {
            axios
              .post(axios.defaults.baseURL + "login", {
                stuId: this.loginForm.username,
                password: this.loginForm.password,
              })
              .then((res) => {
                if (res.data.status == 1) {
                  this.$message.success("登录成功");
                  localStorage.setItem("userId", res.data.data.id);
                  localStorage.setItem("username", res.data.data.username);
                  localStorage.setItem("groupId", res.data.data.groupId);
                  localStorage.setItem("userAvatar", res.data.data.header_url);
                  localStorage.setItem("chatId", this.loginForm.username);
                  // localStorage.setItem("email", res.data.data.email);
                  // console.log("email in login"+res.data.data.email)
                  console.log("组号是"+res.data.data.groupId);
                  sessionStorage.setItem("courseId", 0);
                  sessionStorage.setItem("readingtaskId", 0);
                  this.saveLog("登录成功", this.nowDate);
                  axios
                  .post(axios.defaults.baseURL + "addAllLogs", {
                    username: res.data.data.username,
                    groupId: res.data.data.groupId,
                    stuId: res.data.data.id,
                    log: "登录成功",
                    createTime: this.nowDate,
                  })
                  .then((res) => {});
                  this.$router.push("/home");
                } else {
                  this.$message.error(res.data.message);
                }
              });
          } else {
            this.$message.error("请选择身份");
          }
        }
      });
    },
    register() {
      this.$router.push("/register");
    },
  },
};
</script>

<style lang="less" scoped>
.login_container {
  background-color: #333744;
  height: 100%;
}
.login_box {
  width: 450px;
  height: 340px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.avatar_box {
  height: 130px;
  width: 130px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #eee;
  }
}
.btns {
  display: flex;
  justify-content: flex-end; //尾部对齐
}
.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
</style>